import { FC, useEffect, useState } from "react";
import SelectSearch, { TSelectHandler } from "../common/selectSearch/SelectSearch";
import { useDebounce } from "use-debounce";
import { orderApi } from "../../api/order.api";
import toast from "react-hot-toast";
import { msg } from "../../msg";
import { getErrorFromCatch } from "../../api/api";
import { IInput } from "../form/Input";
import { IOrder } from "../../model/IOrder";

const OrderSelectSearch: FC<IInput & {
  selectHandler?: TSelectHandler<IOrder>,
  minLettersToSearch?: number,
}> = ({
  minLettersToSearch = 3,
  ...props
}) => {
  const [searchState, setSearchState] = useState<string>();
  const [searchStateDebounced] = useDebounce(searchState, 1000);  
  const [isPending, setIsPending] = useState<boolean>(() => false);

  // --
  // -- Orders
  // --
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [fetchOrders] = orderApi.useLazySearchOrderQuery();
  
  useEffect(() => {
    searchStateDebounced !== undefined
    && searchState?.trim()
    && searchState.trim().length >= minLettersToSearch    
    && (async () => {
      setIsPending(() => true);
      try {
        const data = await fetchOrders(searchStateDebounced).unwrap();
        setOrders(() => data || []);  
      } catch (err) {          
        toast.error(`${msg('signin-failed')} ${getErrorFromCatch(err)}`);
      } finally {
        setIsPending(() => false);
      }
    })();
  }, [searchStateDebounced]);

  return (
    <SelectSearch
      placeholder="Choose order"
      searchState={searchState}
      setSearchState={setSearchState}
      isPending={isPending}
      searchResultList={orders.map((order) => ({
        ...order,
        // value: `${order.orderId} ${order?.siName || ''}`,
        value: order.orderId,
        title: `${order.orderId} ${order?.siName || ''}`,
      }))}
      {...props}    
    />
  );

}

export default OrderSelectSearch;
