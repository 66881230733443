import { FC } from "react";

const Divider: FC<{
  vertical?: boolean,
  height?: string,
  width?: string,
  className?: string,
}> = ({
  vertical = false,
  className = '',
  height = '',
  width = '',
}) => (
  <div className={`
    ${vertical
      ? `w-[1px] ${height || 'h-full'} border-r-[1px]`
      : `h-[1px] ${width || 'w-full'} border-b-[1px]`
    } 
    ${className} 
    border-border_color
  `}
  >&nbsp;</div>
);

export default Divider;
