import { FC } from 'react';
import { WrapInLayout } from '../layouts';
import Page2Line from '../layouts/dashboard/Page2Line';
import TitlePage from '../components/common/title/TitlePage';
import UserForm from '../components/users/UserForm';

const UserNewPage: FC = () => {
  return (
    <WrapInLayout title="New User" layout="dashboard">
      <Page2Line      
        noPadding
        header={(
          <TitlePage>Add New User</TitlePage>
        )}
      >
        <UserForm />
      </Page2Line>
    </WrapInLayout>
  );
};

export default UserNewPage;
