import { FC } from "react";
import { icons } from "../../icons/IconSet";

const FileList: FC<{
  files: File[],
  setFiles?: (files: File[]) => unknown,
  disabled?: boolean,
}> = ({
  files,
  setFiles,
  disabled = false,
}) => {
  const handleDeleteFile = (indx: number) => () => !disabled && setFiles && files?.[indx] && setFiles([
    ...files.slice(0, indx),
    ...files.slice(indx + 1),
  ]);

  return !files?.length
  ? null
  : (
    <div className={`flex flex-wrap gap-x-6 gap-y-4 mx-2 items-center ${disabled ? 'opacity-50' : ''}`}>
      {files.map((file, i) => (
        <div key={`${file.name}-${i}`} className="relative bg-gray-100 rounded-md px-2 py-1">
          {file.name}
          <div
            className="absolute right-[-7px] top-[-6px] bg-red-400 hover:bg-red-500 transition-all text-white rounded-[50%] p-[1px] cursor-pointer"
            tabIndex={-1}
            role="button"
            onClick={handleDeleteFile(i)}
          >
            {icons('x', { width: '12px' })}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FileList;
