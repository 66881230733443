import { IUser } from '../model/IUser';
import { ICommonInitState } from '../store/commonSlice';
import api from './api';

export const orderApi = api.injectEndpoints({
  endpoints: (build) => ({
    searchOrder: build.query<any, string>({
      query: (orderId) => ({
          url: '/ordermanagement/order',
          params: { orderId }
        }),
    }),
  }),
});