import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import Input, { IInput } from "../../form/Input";
import SearchInputListSkeloton from "../skeletons/SearchInputListSkeloton";

export interface ISearchresultItem {
  value: string,
  title?: string,
  [key: string]: any,
};
export type TSelectHandler<T> = (chosenData: T) => unknown;

export default function SelectSearch({
  handle,
  searchResultList,
  isPending = false,
  searchState,
  setSearchState,
  selectHandler,
  error,
  // - Standart props for <Input /> component
  ...otherOptions 
} : IInput & {
  searchState: string | undefined,
  setSearchState: React.Dispatch<React.SetStateAction<string | undefined>>,
  searchResultList?: ISearchresultItem[],
  isPending?: boolean,
  // - If it exists, default handler will be changed using this handler onClick
  selectHandler?: TSelectHandler<any>, 
}) {
  // -- Controlling of search result showing
  const [isResultShown, setResultShown] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [isMouseOn, setIsMouseOn] = useState<boolean>(false);
  const [isResultShownDebounced] = useDebounce(isResultShown, 500);

  useEffect(() => {
    !isFocus && !isMouseOn && setResultShown(() => false);
  }, [isFocus, isMouseOn]);

  return (
    <div className="relative mt-1">
      <Input
        {...otherOptions}
        handle={(e) => {            
          handle && handle(e);
          setSearchState(() => e.target.value || '');
          setResultShown(() => true);        
        }}
        onBlur={() => setIsFocus(() => false)}
        onFocus={() => setIsFocus(() => true)}
        type="text"
        role="combobox"
        aria-controls="options"
        aria-expanded="false"
        isError={!!error}
      />

      <ul 
        className={`
          absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
          ${(!isResultShownDebounced && !isPending) || (!isPending && searchResultList?.length === 0) ? 'hidden' : ''}
        `}
        id="options"
        role="listbox"
        onMouseLeave={() => setIsMouseOn(() => false)}
        onMouseOver={() => setIsMouseOn(() => true)}
      >
        {isPending
         ? <li><SearchInputListSkeloton /></li>
         : !!searchResultList?.length && searchResultList.map(({ title, value, ...options }) => 
            <li
                key={value}
                className="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                id={value}
                role="option"
                aria-selected={false}
                tabIndex={-1}
                onClick={() => {
                  handle && handle({ target: { value }} as ChangeEvent<HTMLInputElement>);
                  selectHandler && selectHandler({ title, value, ...options });
                }
                }
              >
                <span className="block truncate">{title || value}</span>
                {value === otherOptions.value && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary">
                    <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                      <path fillRule="evenodd" d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z" clipRule="evenodd" />
                    </svg>
                  </span>
                )}
            </li>
         )
        }  
      </ul>
      {!!error && <div className="text-pink-600 text-sm mt-1">{error}</div>}
    </div>
  );
};
