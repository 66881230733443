import { z } from "zod";
import checkFormStateByZod, { IZodErrorsState } from "../utils/checkFormStateByZod";
import { IEditUser, IEditUserPassword, IUser, IUserRequest, TUserRequest } from "../model/IUser";
import { useTypedSelector } from "./useTypedSelector";
import { TUserState, getCurrentUser } from "../store/authSlice";
import { IRole, ROLES, TRole } from "../model/IRole";
import { userApi } from "../api/user.api";
import { getErrorFromCatch } from "../api/api";
import { msg } from "../msg";
import toast from "react-hot-toast";
import { Option } from "react-multi-select-component";
import { IVendorRoleFull } from "../model/IVendor";
import { IAgentRoleFull } from "../model/IAgent";

export function useUser() {
  const currentUser = useTypedSelector(getCurrentUser());

  // --
  // -- Data checking scheme
  // --
  const newUserRequestSchema = z.object({
    email: z
      .string()
      .email(msg('user-email-unsuitable'))
      .min(1, msg('user-no-email')),
    password: z
      .string()
      .min(4, msg('user-short-password')),
    passwordConfirm: z
      .string()
      .min(4, msg('user-short-password')),
    roles: z
      .string()
      .min(3, msg('user-no-roles')),
  } as Record<keyof IUserRequest, any>)
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: "custom",
        message: msg('user-password-no-match'),
        path: ['passwordConfirm'],
      });
    }
  });

  // -- scheme to update main user information by admin
  const editUserMainRequestSchema = z.object({});

  // -- scheme to update passwords user by admin
  const editUserPassRequestSchema = z.object({
    password: z
      .string()
      .min(4, msg('user-short-password')),
    passwordConfirm: z
      .string()
      .min(4, msg('user-short-password')),
  } as Record<keyof IUserRequest, any>)
  .superRefine(({ passwordConfirm, password }, ctx) => {
    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: "custom",
        message: msg('user-password-no-match'),
        path: ['passwordConfirm'],
      });
    }
  });

  // -- scheme to update current user information
  const editUserRequestSchema = z.object({
    password: z.string(),
    passwordConfirm: z.string(),
  } as Record<keyof IEditUserPassword, any>)
  .superRefine(({ password, passwordConfirm }, ctx) => {
    // - there isn't a new password
    if (!passwordConfirm && !password) return;

    // - there isn't the current password
    // !currentPassword && ctx.addIssue({
    //     code: "custom",
    //     message: msg('user-no-current-password'), // no current
    //     path: ['currentPassword'],
    //   });
    
    // - the passwords is too short
    // currentPassword.length < 4 && ctx.addIssue({
    //     code: "custom",
    //     message: msg('user-short-password'),
    //     path: ['currentPassword'],
    //   });
    password.length < 4 && ctx.addIssue({
      code: "custom",
      message: msg('user-short-password'),
      path: ['password'],
    });
    passwordConfirm.length < 4 && ctx.addIssue({
      code: "custom",
      message: msg('user-short-password'),
      path: ['passwordConfirm'],
    });

    if (passwordConfirm !== password) {
      ctx.addIssue({
        code: "custom",
        message: msg('user-password-no-match'),
        path: ['passwordConfirm'],
      });
    }
  });

  // --
  // -- Data Getting Methods
  // --
  const [tryToAddUser] = userApi.useAddUserMutation();
  const [tryToDeleteUser] = userApi.useDeleteUserMutation();
  const [tryToEditUser] = userApi.useEditUserMutation();

  // -- 
  // -- Methods
  // -- 
  const convertRolesToIdsStr = (roles?: IRole[]): string => (roles || [])
    .map(({ role }) => role)
    .join(',');

  const convertVendorFullRolesToRolesStr = (vFullRoles?: IVendorRoleFull[]): string => (vFullRoles || [])
    .map(({ role }) => role?.id)
    .join(',');

  const convertAgentFullRolesToRolesStr = (vFullRoles?: IAgentRoleFull[]): string => (vFullRoles || [])
    .map(({ role }) => role?.id)
    .join(',');

  const convertOptionRolesToIdsStr = (roleOptions?: Option[]): string => (roleOptions || [])
    .map(({ value }) => value)
    .join(',');

  const convertRolesIdsToRoles = (rolesIdsStr: string): Option[] => rolesIdsStr
    .split(',')
    .reduce((acc, roleId) => [
      ...acc,
      ...(ROLES?.[roleId as TRole]
        ? [{ label: ROLES[roleId as TRole], value: roleId }]
        : []
      )
    ], [] as Option[]) || [];


  const convertIdsToUsers = (ids?: string | number, users?: IUser[]): IUser[] => (String(ids) || '')
    .split(',')
    .map((id) => (users || []).find(({ id: originalId }) => originalId === id ))
    .filter((user) => !!user) as IUser[];

  // -- 
  // -- Get User Roles
  // -- 
  const getUserRoles = (user: TUserState = currentUser): TRole[] => (user?.userRole || [])
    .map(({ role }) => role);

  // -- 
  // -- Check if user has one of roles
  // -- 
  const hasRoles = (roles: TRole[], user: TUserState = currentUser): boolean => 
    (new Set([...getUserRoles(user), ...roles])).size !== [...getUserRoles(user), ...roles].length;

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const addUser = async (userState: IUserRequest): Promise<IZodErrorsState<IUserRequest> | null> => {   
    // 1. Check data
    const errs = checkFormStateByZod(newUserRequestSchema, userState);
    if (errs && Object.values(errs)?.length) return errs;  

    const { vendor, vendorRole, agentRole, ...state } = userState; 

    // -- Preparing of vendor information
    const vendorRoles = vendorRole && vendor
      ? (vendorRole.trim().split(',') || []).map((vRole) => ({
          vendorId: Number(vendor),
          vendorRoleId: String(vRole),
        }))
      : [];

    // -- Preparing of agent information
    const agentRoles = agentRole
      ? (agentRole.trim().split(',') || []).map((vRole) => ({
          agentRoleId: String(vRole),
        }))
      : [];

    // - 2. Send data
    try {
      await tryToAddUser({
        ...state,
        vendorRoles,
        agentRoles,
      }).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('user-create-failed')} \n${getErrorFromCatch(err)}`) 
      return { email: getErrorFromCatch(err) }
    }
  };

  // --
  // -- Returns string = API error
  // -- null - OK
  // --
  const deleteUser = async (userId: string): Promise<string | null> => {
  
    // 1. Check data
    if (!userId) return msg('not-enough-data');  
    
    // - 2. Send data
    try {
      await tryToDeleteUser(userId).unwrap();;
      return null;
    } catch (err) {
      toast.error(`${msg('user-delete-failed')} \n${getErrorFromCatch(err)}`) 
      return getErrorFromCatch(err);
    }
  };

  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const editMainUserInfo = async (id: string, userState: IEditUser): Promise<IZodErrorsState<IEditUser> | null> => {
    // 1. Check data
    const errs = checkFormStateByZod(editUserMainRequestSchema, userState);
    if (errs && Object.values(errs)?.length) return errs;   
    
    const { vendor, vendorRole, agentRole, ...state } = userState; 
    const vendorRoles = vendorRole && vendor
      ? (vendorRole.trim().split(',') || []).map((vRole) => ({
          vendorId: Number(vendor),
          vendorRoleId: String(vRole),
        }))
      : [];

    // -- Preparing of agent information
    const agentRoles = agentRole
    ? (agentRole.trim().split(',') || []).map((vRole) => ({
        agentRoleId: String(vRole),
      }))
    : [];

    // - 2. Send data
    try {
      await tryToEditUser({
        id,
        ...state,
        vendorRoles,
        agentRoles,
      }).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('user-edit-failed')} \n${getErrorFromCatch(err)}`) 
      return { name: getErrorFromCatch(err) }
    }
  };
  
  const editPassUserInfo = async (id: string, userState: IEditUserPassword): Promise<IZodErrorsState<IEditUserPassword> | null> => {
    // 1. Check data
    const errs = checkFormStateByZod(editUserPassRequestSchema, userState);
    if (errs && Object.values(errs)?.length) return errs;   
    
    // - 2. Send data
    try {
      await tryToEditUser({ 
        id,
        password: userState.password,
        passwordConfirm: userState.passwordConfirm,
      }).unwrap();
      return null;
    } catch (err) {
      toast.error(`${msg('user-edit-failed')} \n${getErrorFromCatch(err)}`) 
      return { password: getErrorFromCatch(err) }
    }
  };
  
  // --
  // -- Returns string = API error
  // -- False - OK
  // --
  const editUserInfo = async (id: string, userState: IEditUser): Promise<IZodErrorsState<IEditUser> | string | false> => {
    // 1. Check data
    const errs = checkFormStateByZod(editUserRequestSchema, userState);
    if (errs && Object.values(errs)?.length) return errs;   
    
    // - 2. Send data
    try {
      await tryToEditUser({ id, ...userState }).unwrap();
      return false;
    } catch (err) {
      return getErrorFromCatch(err);
    }
  };

  return {
    addUser,
    deleteUser,
    editMainUserInfo, // - for admin flow
    editPassUserInfo, // - for admin flow
    editUserInfo, // - for user flow
    convertIdsToUsers,

    hasRoles,
    getUserRoles,

    convertOptionRolesToIdsStr,
    convertRolesIdsToRoles,
    convertRolesToIdsStr,
    convertVendorFullRolesToRolesStr,
    convertAgentFullRolesToRolesStr,
  };
}