import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getCurrentUser } from "../../store/authSlice";
import getInitialsName from "../../utils/getInitialsName";
import { icons } from "../../icons/IconSet";
import DropMenu from "../common/dropmenu/DropMenu";
import { useAuth } from "../../hooks/useAuth";

const UserWidget: FC = () => {
  const user = useTypedSelector(getCurrentUser());
  const { handleLogout } = useAuth();

  return (
    <div className="flex gap-2 justify-between">
      <div className="flex w-[38px] h-[38px] bg-gray-400 rounded-lg items-center justify-center text-white font-semibold tracking-wide">
        {getInitialsName(user?.name)}
      </div>
      <div className="grid w-[120px] overflow-hidden">
        <div className="truncate">{user?.name ?? 'Nameless'}</div>
        <div className="truncate text-gray-500">{user?.email ?? ''}</div>
      </div>
      <div className="flex items-center">
        <DropMenu
          width="238px"
          top="36px"
          right="-47px"
          icon={icons('chevron-down', { width: '18px' })}
          links={[
            { name: 'Preferences' },
            { name: 'Company Policies and Procedures' },
            {},
            { name: 'Log Out', onClick: handleLogout },
          ]}
        />
      </div>
    </div>
  );
};

export default UserWidget;
