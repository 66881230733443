import { TUsersFilterState } from '../components/users/UserList';
import { IUser, IUserRequest, TUserRequest } from '../model/IUser';
import { IPageParams } from './IApi';
import { IPagesResponse } from './IPagesResponse';
import api from './api';

export type IUserScope = 'tickets_agent' | 'tickets_vendor' | 'tickets';

export interface IGetUsersRequest {
  searchStr: string,
  scope?: IUserScope,
}

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query<IUser[], IGetUsersRequest >({
      query: ({ searchStr, scope }) => ({
          url: 'users/searchlist',
          params: {
            email: searchStr,
            ...(scope ? { scope } : {}), 
          }
        }),
        providesTags: ['AllUsers'],
    }),

    getUserList: build.query<IPagesResponse<IUser[]>, IPageParams & TUsersFilterState >({
      query: ({ sort, sortDir, ...params }) => ({
          url: 'users',
          params: {
            ...Object.entries(params).reduce((acc, [key, value]) => ({
              ...acc,
              ...(value !== '' ? { [key]: value } : {})
            }), {}),
            ...(sort ? { sort: `${sort},${sortDir}` }  : {}),
          },
        }),
        providesTags: ['Users'],
    }),

    getUser: build.query<IUser, string>({
      query: (userId) => ({
          url: `users/${userId}`
      }),
      providesTags: ['User'],
    }),

    addUser: build.mutation<IUser, IUserRequest>({
      query: ({ roles, ...body }) => ({
          url: 'users',          
          method: 'POST',
          body: {
            ...body,
            roles: roles?.trim()?.split(',') || [],
          },
        }),
        invalidatesTags: ['Users', 'AllUsers']
    }),

    editUser: build.mutation<IUser, TUserRequest>({
      query: ({ id, ...body }) => ({
          url: `users/${id}`,
          method: 'PATCH',
          body: {
            ...body,
            ...((body as any)?.roles
                ? { roles: (body as any)?.roles?.trim()?.split(',') || [] }
                : {}
            ),
          },
        }),
        invalidatesTags: ['Users', 'User', 'AllUsers']
    }),

    // editUserPassword: build.mutation<IUser, TUserRequest>({
    //   query: ({ id, ...body }) => ({
    //       url: `users/${id}`,
    //       method: 'PATCH',
    //       body,
    //     }),
    //     invalidatesTags: ['Users', 'User', 'AllUsers']
    // }),

    deleteUser: build.mutation<IUser, string>({
      query: (userId) => ({
          url: `users/${userId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Users', 'AllUsers', 'User']
    }),

  }),
});
