import { FC, useEffect, useState } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getTiketsTableFields, setFields, toggleField, TTicketsTableColOption } from "../../store/ticketsFilterSlice";
import DropMenu from "../common/dropmenu/DropMenu";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import { TTicketTableCols } from "./TicketsTable";

const TicketsTableColsFilter : FC = () => {
  const dispatch = useTypedDispatch();
  const fields = useTypedSelector(getTiketsTableFields()); 
  const [selectedAmount, setSelectedAmount] = useState<number>();

  useEffect(() => {
    fields
    && (
      Object.values(fields).length !== Object.values(fields).filter(Boolean).length
        ? setSelectedAmount(() => Object.values(fields).filter(Boolean).length)
        : setSelectedAmount(() => undefined)
    );
  }, [fields]);

  const handleReset = () => fields && dispatch(setFields(Object.keys(fields).reduce(
    (acc, key) => ({
      ...acc,
      [key]: true,
    }), {} as TTicketsTableColOption)));

  return !fields || !Object.values(fields)?.length
    ? null
    : (
      <DropMenu
        width="238px"
        links={[
          ...Object.entries(fields).map(([name, active], i) => ({
              name: (
                <div
                  key={`col-${name}`}
                  tabIndex={-1}
                  role="button"
                  onClick={() => dispatch(toggleField(name as TTicketTableCols))}
                  className="flex justify-between items-center"
                >
                  {name}
                  <Checkbox
                    name={`col-${name}`}
                    value={active}
                    enablePropagation
                  />
                </div>
              ),
            })
          ),
          {
            name: (
              <div className={`
                flex justify-end underline underline-offset-2
                ${selectedAmount ? '' : 'opacity-40'}
              `}>
                Reset
              </div>
            ),
            onClick: handleReset,
          }          
        ]}
      >
        <Button
          isNotFullWidth
          type={selectedAmount ? 'submit' : 'button'}
          className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
        >
          Columns 
          {!!selectedAmount && <span>{selectedAmount}</span>}
          {icons('chevron-down', { width: '14px' })}
        </Button>
      </DropMenu>
    );
};

export default TicketsTableColsFilter;
