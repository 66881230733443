import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { TTicketFilterState } from '../api/ticket.api';
import { TTicketTableCols } from '../components/tickets/TicketsTable';

interface IFilterpredefinedOption {
  title: string,
  value: string,
  tag: keyof TTicketFilterState,
  isActive: boolean,
};
export type TTicketsTableColOption = Record<TTicketTableCols, boolean>;
export interface IticketsFilterTypeOption {
  id: number,
  name: string,
  isActive: boolean,
}
export interface IticketsFilterDate {
  createdFrom?: string,
  createdTill?: string,
}

export const TICKETS_FILTER_SEARCH_TAGS = {
  orderId: 'Order #',
  ticketId: 'Ticket #',
  customerEmail: 'Customer Email',
  customerName: 'Customer Name',
  customerPhone: 'Customer Phone'
};
export interface IticketsFilterSearch {
  type: keyof typeof TICKETS_FILTER_SEARCH_TAGS,
  search: string,
}

export interface IticketsFilterInitState {
  predefined: IFilterpredefinedOption[],
  fields?: TTicketsTableColOption,
  types?: IticketsFilterTypeOption[],
  date?: IticketsFilterDate,
  search: IticketsFilterSearch,
  assignedToAgent?: string,
  assignedToVendor?: string,
}

const ticketsFilterSlice = createSlice({
  name: 'ticketsFilterSlice',
  initialState: {
    predefined: [
      { title: 'All', value: '', tag: 'ticketStatuses', isActive: true },
      { title: 'Urgent', value: '4', tag: 'ticketPriorities', isActive: false },
      { title: 'New Tickets', value: '2', tag: 'ticketStatuses', isActive: false },
      { title: 'Open', value: '1', tag: 'ticketStatuses', isActive: false },
      { title: 'Pending', value: '3', tag: 'ticketStatuses', isActive: false },
      { title: 'Closed', value: '4', tag: 'ticketStatuses', isActive: false },
      { title: 'Archive', value: '5', tag: 'ticketStatuses', isActive: false },
      { title: 'Assigned', value: '1', tag: 'ticketAssignedToMe', isActive: false },
    ],
    fields: undefined,
    types: undefined,
    date: undefined,
    assignedToAgent: undefined,
    assignedToVendor: undefined,
    search: {
      type: 'orderId',
      search: '',
    },
  } as IticketsFilterInitState,

  reducers: {
    setPredefined: (state: IticketsFilterInitState, action: PayloadAction<IFilterpredefinedOption[]>) => {
      state.predefined = [ ...state.predefined, ...action.payload];
    },
    switchOnPredefined: (state: IticketsFilterInitState, action: PayloadAction<{ title: string, active: boolean }>) => {
      // -- Get option index
      const optionIndx = state.predefined.findIndex((opt) => opt.title === action.payload.title);
      if (optionIndx < 0) return;

      // -- Switch on/off option and switch off options with the same tag
      state.predefined = [ ...state.predefined ].map((option, i) => ({
        ...option,
        ...(
          (i === optionIndx && { isActive: action.payload.active })
          || (option.tag === state.predefined[optionIndx].tag && { isActive: false })
          || {}        
        )
      }));
    },

    setFields: (state: IticketsFilterInitState, action: PayloadAction<TTicketsTableColOption>) => {
      state.fields = action.payload;
    },
    toggleField: (state: IticketsFilterInitState, action: PayloadAction<TTicketTableCols>) => {
      if (!state.fields) return;
      const tmpFields = { ...state.fields };
      tmpFields[action.payload] = !tmpFields[action.payload];
      state.fields = tmpFields;
    },

    setTicketTypes: (state: IticketsFilterInitState, action: PayloadAction<IticketsFilterTypeOption[]>) => {
      state.types = action.payload;
    },
    toggleType: (state: IticketsFilterInitState, action: PayloadAction<number>) => {      
      const typeIndx = (state?.types || []).findIndex(({ id }) => id === action.payload);
      if (!state.types || typeIndx < 0) return;

      const tmpTypes = [ ...state.types ];
      tmpTypes[typeIndx] = {
        ...tmpTypes[typeIndx],
        isActive: !tmpTypes[typeIndx].isActive,
      }
      state.types = tmpTypes;
    },

    setTicketDates: (state: IticketsFilterInitState, action: PayloadAction<{ createdFrom?: string, createdTill?: string }>) => {
      state.date = action.payload;
    },

    setTicketSearch: (state: IticketsFilterInitState, action: PayloadAction<IticketsFilterSearch>) => {
      state.search = action.payload;
    },
  },
});

// Reducers
export default ticketsFilterSlice.reducer;
export const {
  setPredefined,
  switchOnPredefined,
  setFields,
  toggleField,
  setTicketTypes,
  toggleType,
  setTicketDates,
  setTicketSearch,
} = ticketsFilterSlice.actions;

// -- 
// -- Selectors
// -- 
export const getAllfilters = () => (state: RootState) => state.ticketsFilterSlice;
export const getPredefined = () => (state: RootState) => state.ticketsFilterSlice.predefined;
export const getTiketsTableFields = () => (state: RootState) => state.ticketsFilterSlice.fields;
export const getTiketsTypesFilter = () => (state: RootState) => state.ticketsFilterSlice.types;
export const getTiketsDateFilter = () => (state: RootState) => state.ticketsFilterSlice.date;
export const getTiketsFilterSearch = () => (state: RootState) => state.ticketsFilterSlice.search;