import { FC, PropsWithChildren, ReactNode } from "react";

const InfoWidget: FC<PropsWithChildren<{
  title: ReactNode
}>> = ({
  title,
  children,
}) => (
<div className="flex flex-col gap-[2px] justify-start">
  <div className="font-bold">{title}</div>
  <div className="">{children}</div>
</div>
);

export default InfoWidget;
