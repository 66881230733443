import { IUser } from '../model/IUser';
import { ICommonInitState } from '../store/commonSlice';
import api from './api';

export const commonApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCommon: build.query<ICommonInitState, unknown>({
      query: () => ({
          url: 'tickets/common'
        }),
    }),
  }),
});