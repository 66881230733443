import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userApi } from '../api/user.api';
import Loading from '../components/common/loading/Loading';
import TitlePage from '../components/common/title/TitlePage';
import UserEditForm from '../components/users/UserEditForm';
import { WrapInLayout } from '../layouts';
import Page2Line from '../layouts/dashboard/Page2Line';

const UserPage: FC = () => {
  let { idUser } = useParams();
  const navigate = useNavigate();
  const { data: user } = userApi.useGetUserQuery(idUser || '', { skip: !idUser });

  !idUser && navigate('/');
  
  return (
    <WrapInLayout title="New User" layout="dashboard">
      <Page2Line      
        noPadding
        header={(
          <TitlePage>User {user?.name || ''} ({user?.email || ''})</TitlePage>
        )}
      >
        {!user
          ? <Loading />
          : <UserEditForm user={user} />
        }        
      </Page2Line>
    </WrapInLayout>
  );
};

export default UserPage;
