import { FC, useEffect, useState } from "react";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getTicketTypes } from "../../store/commonSlice";
import { getTiketsTypesFilter, setTicketTypes, toggleType } from "../../store/ticketsFilterSlice";
import DropMenu from "../common/dropmenu/DropMenu";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";

const TicketTypesFilter : FC = () => {
  const dispatch = useTypedDispatch();
  const allTicketTypes = useTypedSelector(getTicketTypes());
  const ticketTypesState = useTypedSelector(getTiketsTypesFilter());
  const [selectedAmount, setSelectedAmount] = useState<number>();
  
  useEffect(() => {
    ticketTypesState
    && (
      ticketTypesState.length !== ticketTypesState.filter(({ isActive }) => isActive).length
        ? setSelectedAmount(() => ticketTypesState.filter(({ isActive }) => isActive).length)
        : setSelectedAmount(() => undefined)
    );
  }, [ticketTypesState]);

  // --
  // -- Init ticket types
  // --
  const initTicketTypes = () =>     
    allTicketTypes?.length
    && dispatch(setTicketTypes(allTicketTypes.map((type) => ({
      ...type,
      isActive: true,
    }))));

  useEffect(() => { ticketTypesState === undefined && initTicketTypes(); }, [allTicketTypes]);

  return !ticketTypesState || !ticketTypesState?.length 
    ? null
    : (
      <DropMenu
        width="238px"
        links={[
          ...ticketTypesState.map(({ id, name, isActive }) => ({
            name: (
              <div
                key={`col-${name}`}
                tabIndex={-1}
                role="button"
                onClick={() => dispatch(toggleType(Number(id)))}
                className="flex justify-between items-center"
              >
                {name}
                <Checkbox
                  name={`col-${name}`}
                  value={isActive}
                  enablePropagation
                />
              </div>
            ),
          })),
          {
            name: (
              <div className={`
                flex justify-end underline underline-offset-2
                ${selectedAmount ? '' : 'opacity-40'}
              `}>
                Reset
              </div>
            ),
            onClick: initTicketTypes,
          },
        ]}
      >
        <Button
          isNotFullWidth
          type={selectedAmount ? 'submit' : 'button'}         
          className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
        >
          Ticket Types
          {
            ticketTypesState.length !== ticketTypesState.filter(({ isActive }) => isActive).length
            && <span>{ticketTypesState.filter(({ isActive }) => isActive).length}</span>
          }
          {icons('chevron-down', { width: '14px' })}
        </Button>
      </DropMenu>
    );
}

export default TicketTypesFilter;
