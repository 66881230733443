import { FC, useEffect, useState } from "react";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import convertDateFromDateStr from "../../utils/convertDateFromDateStr";
import convertDateStrFromDate from "../../utils/convertDateStrFromDate";
import Button from "../form/Button";
import { IInput, TInputEvent } from "../form/Input";

// -- 'yyyy-MM-dd:yyyy-MM-dd' -> [Date, Date]
const convertPeriodDateFromDateStr = (periodStr: string) => {
  const dates = periodStr.split(':');
  return [convertDateFromDateStr(dates[0]), convertDateFromDateStr(dates[1])]
}

const DateRange: FC<IInput> = ({ size, handle, value }) => {
  const [state, setState] = useState({
    period: {
      startDate: value ? convertPeriodDateFromDateStr(String(value))[0] : undefined,
      endDate: value ? convertPeriodDateFromDateStr(String(value))[1] : undefined,
      // startDate: addDays(new Date(), 1),
      // endDate: addDays(new Date(), 1),
      key: 'period'
    }
  });

  useEffect(() => {
    const startDate = state.period.startDate
      ? convertDateStrFromDate(state.period.startDate)
      : '';

    const endDate = state.period.endDate
      ? convertDateStrFromDate(state.period.endDate)
      : '';

    handle && handle({
        target: {
          value: startDate || endDate
            ? `${startDate}:${endDate}`
            : ''
        }
      } as TInputEvent
    );
  }, [state.period]);

  const handleClearRange = () => {   
    setState((prevState) => ({
      period: {
        ...prevState.period,
        startDate: undefined,
        endDate: undefined,
      }
    }));
  } 

  return (
    <>    
      <DateRangePicker
        onChange={item => setState({ ...state, ...item })}
        ranges={[state.period]}
      />
      <div className="flex justify-end pr-4">
        <Button
          isNotFullWidth
          size="small"
          onClick={handleClearRange}
        >
          Reset
        </Button>
      </div>
    </>
  )
  ;
}

export default DateRange;
