import { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { IPageParams } from "../../api/IApi";
import { getErrorFromCatch } from "../../api/api";
import { userApi } from "../../api/user.api";
import { CONFIG } from "../../config";
import { useConfirm } from "../../hooks/useConfirm";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useUser } from "../../hooks/useUser";
import { ROLES } from "../../model/IRole";
import { msg } from "../../msg";
import { getVendors } from "../../store/commonSlice";
import parseISOToString from "../../utils/parseISOToString";
import Filter, { IFilter, IFilterState } from "../common/filter/Filter";
import Table, { ITableTh } from "../common/table/Table";
import UserActionMenu from "./UserActionMenu";
import { getUserlistPageParams, setUserlistPageParams } from "../../store/userSlice";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";

export type TUsersFilterFields =
| 'email'
| 'id'
| 'phone';

export type TUsersFilterState = IFilterState<Record<TUsersFilterFields, string>>;

const UserList : FC = () => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const dispatch = useTypedDispatch();
  const { deleteUser } = useUser();

  // -- Page parameters and filter states
  // const [pageParams, setPageParams] = useState<IPageParams>({
  //   page: 0,
  //   size: CONFIG.user.usersPerPage,
  //   q: '',
  //   sort: '',
  //   sortDir: 'asc',
  // });
  const pageParams = useTypedSelector(getUserlistPageParams());

  const [filterState, setFilterState] = useState<IFilterState<Record<TUsersFilterFields, string>>>({
    id: '',
    email: '',
    phone: '',
  }); 

  // -- Load user list
  const { data: users, isFetching, isError, error } = userApi.useGetUserListQuery({
    ...pageParams,
    ...filterState,
  });

  // -- Table header
  const thead: ITableTh[] = [
    { title: 'Email', sortCol: 'email' },
    { title: 'Name', sortCol: 'name' },
    { title: 'Phone', sortCol: 'phone' },
    { title: 'Enabled', sortCol: 'active' },
    { title: 'Roles' },
    { title: 'Created', sortCol: 'registered' },
    { title: '' },
  ];

  // -- Api Error handler
  useEffect(() => {
    isError && error && toast.error(`${msg('user-list')} ${getErrorFromCatch(error)}`);
  }, [isError]);  

  // -- Filter params
  const filterFields: IFilter<TUsersFilterState> = {
    id: { label: 'ID' },
    email: { label: 'Email' },
    phone: { label: 'Phone' },
  };
  
  return (
    <Table
      options={[
        <Filter
          className="w-[250px]"
          fields={filterFields}
          filterState={filterState}
          setFilterState={(newState: TUsersFilterState) =>  setFilterState(newState)}
        />,
      ]}
      isPending={isFetching}
      pageParams={pageParams}
      pagable={users}
      updatePageParams={(newParams: Partial<IPageParams>) => 
        dispatch(setUserlistPageParams({
          ...pageParams,
          ...newParams
        }))
        // setPageParams((prevState) => ({
        //   ...prevState,
        //   ...newParams
        // })) 
      }
      thead={thead} 
      tbody={!users?.content?.length
        ? undefined
        : users.content.map(({ id, name, email, phone, active, userRole, registered}) => ({ 
          onClick: () => navigate(`/users/${id}`),
          data: [
            <NavLink to={`${id}`}>{email}</NavLink>,
            name || ' ',
            phone || ' ',
            active ? 'yes' : 'no',
            (userRole || [])?.map(({ role }) => ROLES?.[role]).join(', '),
            parseISOToString(registered || ''),
            <UserActionMenu userId={id} userEmail={email} />
          ]
        }))
      } 
    />
  );
};

export default UserList;
