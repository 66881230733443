import { Dispatch, FC, SetStateAction } from "react";
import { icons } from "../../icons/IconSet";

const SideBarButton: FC<{
  isSideBar: boolean,
  setIsSideBar: Dispatch<SetStateAction<boolean>>,
}> = ({
  isSideBar,
  setIsSideBar
}) => (
  <div className="flex items-center ">
    <div
      onClick={() => setIsSideBar((prevState) => !prevState)}
      className={`
        bg-white border-gray-300 border-[1px] rounded-md w-[34px] h-[34px] flex justify-center items-center cursor-pointer shadow-sm hover:shadow-md
        transition-all duration-300
        ${isSideBar
          ? 'rotate-0'
          : 'rotate-180'}
      `}
    >
      {icons('arrow-left-to-line', { width: '16px' })}
    </div>
  </div>
);

export default SideBarButton;
