import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getTicketTypes } from "../../store/commonSlice";
import Input, { IInput } from "../form/Input";

const TicketTypeSelect: FC<IInput> = (props) => {
  const types = useTypedSelector(getTicketTypes()); 

  return (
    <Input
      options={types?.map(({ id, name }) => ({
          title: name,
          value: id
        })
      )}
      {...props}
      value={props?.value || types?.[0]?.id}
    />
  );
}

export default TicketTypeSelect;
