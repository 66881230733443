import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getTicketStatuses } from "../../store/commonSlice";
import Input, { IInput } from "../form/Input";
import TicketStatus from "./TicketStatus";

const TicketStatusSelect: FC<IInput> = (props) => {
  const statuses = useTypedSelector(getTicketStatuses()); 

  return (
    <Input
      options={statuses?.map(({ id, name }) => ({
          title: (
            <TicketStatus statusId={id} isNotIcon />),
          value: id
        })
      )}
      {...props}
      value={props?.value || statuses?.[0]?.id}
    />
  );
}

export default TicketStatusSelect;
