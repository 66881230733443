import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { getErrorFromCatch, serializeFieldErrors } from '../api/api';
import { authApi } from '../api/auth.api';
import { msg } from '../msg';
import { ILoginState } from '../pages/SignIn.page';
import StorageService from '../services/storage.service';
import {
  removeUser,
  setUser
} from '../store/authSlice';
import checkFormStateByZod from '../utils/checkFormStateByZod';
import generateRecaptchaV3 from '../utils/generateRecaptchaV3';
import { useTypedDispatch } from './useTypedDispatch';

// --
// -- Determine the form schema
// --
const signInScheme = z.object({
  login: z
    .string({ required_error: msg('login-field-empty') })
    .email(msg('login-field-no-email')),
  password: z
    .string({ required_error: msg('password-field-empty')})
    .min(3, msg('password-field-too-short')),
});

export function useAuth() {
  const dispatch = useTypedDispatch();

  const [tryLogin] = authApi.useLoginMutation();
  const [getMyself] = authApi.useLazyMeQuery();

  const setMyself = async () => {
    const { data } = await getMyself({});  

    return data
      ? dispatch(setUser(data))
      : dispatch(removeUser());
  };

  const unSetMyself = () => {
    dispatch(removeUser());
    storeTokens();
  };

  // --
  // -- return: null - OK, { error? } - Error
  // --
  const handleLogin = async ({ login, password, token }: ILoginState ): Promise<null | Partial<Record<keyof ILoginState, string>>> => {
    // -- Client validation form
    const clientErrs = checkFormStateByZod(signInScheme, { login, password, token });
    if (clientErrs) return clientErrs;

    // -- Generate Recaptcha
    token ||= await generateRecaptchaV3(process.env.REACT_APP_SIGNIN_ACTION);
    if (!token) {
      toast.error(`${msg('empty-recaptcha')}`);
      return {};
    }
    
    try {
      const data = await tryLogin({ login, password, token }).unwrap();
      storeTokens(data?.accessToken, data?.refreshToken);
      await setMyself();
      return null;
    } catch (err) {
      toast.error(`${msg('signin-failed')} ${getErrorFromCatch(err)}`);           
      return serializeFieldErrors(err) ?? { email: getErrorFromCatch(err) };
    }    
  };

  const handleLogout = () => {
    unSetMyself();
  };

  const storeTokens = (access = '', refresh = ''): void => {
    StorageService.setToken('access', access);
    StorageService.setToken('refresh', refresh);
  };

  return {
    setMyself,
    unSetMyself,

    handleLogin,
    handleLogout,
  };
}
