import { FC, PropsWithChildren, ReactNode } from "react";
import TicketHeader from "../../components/tickets/TicketHeader";
import TicketTabs from "../../components/tickets/TicketTabs";
import NotificationWidget from "./NotificationWidget";

const Page3Line: FC<PropsWithChildren<{
  preHeader?: ReactNode,
  header?: ReactNode,
  children?: ReactNode,
}>> = ({
  preHeader,
  header,
  children,
}) => (
  <div className="bg-white rounded-md border-border_color border-[1px] h-full flex flex-col">
    <div className="h-[50px] border-border_color border-b-[1px] flex justify-between">
      {preHeader ?? <TicketTabs />}
      <NotificationWidget />
    </div>
    <div className="min-h-[60px] bg-bg_light border-border_color border-b-[1px] p-4">
      {header ?? <TicketHeader/>}
    </div>
    <div className="flex-1 overflow-x-scroll stylledScroll">
      {children}
    </div>          
  </div>
);

export default Page3Line;
