import React, { FC } from "react";
import { TUpdatePageParams } from "./Table";
import { IApiPagable } from "../../../api/IPagesResponse";

const TablePagination : FC<{
  updatePageParams: TUpdatePageParams,
  pagable: IApiPagable,
}> = ({
  updatePageParams,
  pagable,
}) => {
  if (pagable.totalPages <= 1) return null;

  const handleToPage = (page: number) => () => { updatePageParams({ page })}

  const renderPageLink = (page: number): React.ReactNode =>
    page < 0 || page >= pagable.totalPages
      ? null
      : (
        <span
          key={`${page}of${pagable.totalPages}`}
          onClick={handleToPage(page)}
          role="button"
          tabIndex={-1}
          className={`
            ${pagable.number === page
              ? 'text-primary border-primary hover:border-primary hover:text-primary'
              : 'text-gray-500 hover:border-gray-300 hover:text-gray-700'
            }
            inline-flex items-center border-t-2 px-4 pt-4 font-medium 
          `}
        >
          {page + 1}
        </span>
      );

  const renderSeparator = () => <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 font-medium text-gray-500">...</span>;

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 max-w-[500px] mx-auto">
      <div className="-mt-px flex w-0 flex-1">
        {!!!pagable?.first && (
          <span
            onClick={handleToPage(pagable.number - 1)}
            role="button"
            tabIndex={-1}
            className="inline-flex gap-2 items-center border-t-2 border-transparent pr-1 pt-4 font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-left"><path d="m15 18-6-6 6-6"/></svg>
            Previous
          </span>
        )}
      </div>

      <div className="hidden md:-mt-px md:flex">
        {pagable.number >= 2 && pagable.totalPages > 3 && renderPageLink(0)}
        {pagable.number > 2 && pagable.totalPages > 4 && renderSeparator()}
        {[...Array(3)].map((el, i) => {
          if (pagable.number - 1 < 0) return renderPageLink(pagable.number + i)
          if (pagable.number + 1 >= pagable.totalPages) return renderPageLink(pagable.number + i - 2)
          return renderPageLink(pagable.number + i - 1)
        })}
        {pagable.number < pagable.totalPages - 3 && pagable.totalPages > 4 && renderSeparator()}
        {pagable.number < pagable.totalPages - 2 && pagable.totalPages > 3 && renderPageLink(pagable.totalPages - 1)}
      </div>

      <div className="-mt-px flex w-0 flex-1 justify-end">
        {!!!pagable?.last && (
          <span
            onClick={handleToPage(pagable.number + 1)}
            role="button"
            tabIndex={-1}
            className="inline-flex gap-2 items-center border-t-2 border-transparent pr-1 pt-4 font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >        
            Next
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-right"><path d="m9 18 6-6-6-6"/></svg>
          </span>
        )}
      </div>
    </nav>

  );
}

export default TablePagination;
