import { ITableTh, ITableThead } from "./Table";
import React, { FC, useEffect, useState } from "react";

const TableThead : FC<ITableThead> = ({
  thead,
  isPending = false,
  pageParams,
  updatePageParams,
}) => (
    <thead className={`${isPending ? 'opacity-20' : ''} z-0 relative`}>
      <tr>
        {thead.map(({ title = '', sortCol }) => (
          <th
            key={`${title}-${sortCol}`}
            scope="col"
            className={`
              sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-1 pr-1 text-left font-bold backdrop-blur backdrop-filter sm:pl-6 lg:pl-8
              ${sortCol && !isPending ? 'cursor-pointer': ''}
            `}
            onClick={() => {
              if (isPending || !sortCol) return;
              updatePageParams && updatePageParams(({
                sort: sortCol,
                sortDir: pageParams?.sort === sortCol && pageParams?.sortDir === 'asc'
                  ? 'desc'
                  : 'asc'
              }));
            }}
          >
            <div className="flex items-center gap-[2px]">
              <div className="whitespace-nowrap">{title}</div>
              {sortCol && pageParams?.sort === sortCol && (
                pageParams?.sortDir === 'asc'
                  ? <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.4569 9.73276C6.17123 10.0327 6.18281 10.5074 6.48276 10.7931C6.78271 11.0788 7.25744 11.0672 7.5431 10.7672L6.4569 9.73276ZM12.5431 5.51724C12.8288 5.21729 12.8172 4.74256 12.5172 4.4569C12.2173 4.17123 11.7426 4.18281 11.4569 4.48276L12.5431 5.51724ZM12.5431 4.48276C12.2574 4.18281 11.7827 4.17123 11.4828 4.4569C11.1828 4.74256 11.1712 5.21729 11.4569 5.51724L12.5431 4.48276ZM16.4569 10.7672C16.7426 11.0672 17.2173 11.0788 17.5172 10.7931C17.8172 10.5074 17.8288 10.0327 17.5431 9.73276L16.4569 10.7672ZM12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5H12.75ZM11.25 19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19H11.25ZM7.5431 10.7672L12.5431 5.51724L11.4569 4.48276L6.4569 9.73276L7.5431 10.7672ZM11.4569 5.51724L16.4569 10.7672L17.5431 9.73276L12.5431 4.48276L11.4569 5.51724ZM11.25 5V19H12.75V5H11.25Z" fill="#6B7280"/>
                    </svg>
                  : <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.5431 14.2672C17.8288 13.9673 17.8172 13.4926 17.5172 13.2069C17.2173 12.9212 16.7426 12.9328 16.4569 13.2328L17.5431 14.2672ZM11.4569 18.4828C11.1712 18.7827 11.1828 19.2574 11.4828 19.5431C11.7827 19.8288 12.2574 19.8172 12.5431 19.5172L11.4569 18.4828ZM11.4569 19.5172C11.7426 19.8172 12.2173 19.8288 12.5172 19.5431C12.8172 19.2574 12.8288 18.7827 12.5431 18.4828L11.4569 19.5172ZM7.5431 13.2328C7.25744 12.9328 6.78271 12.9212 6.48276 13.2069C6.18281 13.4926 6.17123 13.9673 6.4569 14.2672L7.5431 13.2328ZM11.25 19C11.25 19.4142 11.5858 19.75 12 19.75C12.4142 19.75 12.75 19.4142 12.75 19H11.25ZM12.75 5C12.75 4.58579 12.4142 4.25 12 4.25C11.5858 4.25 11.25 4.58579 11.25 5H12.75ZM16.4569 13.2328L11.4569 18.4828L12.5431 19.5172L17.5431 14.2672L16.4569 13.2328ZM12.5431 18.4828L7.5431 13.2328L6.4569 14.2672L11.4569 19.5172L12.5431 18.4828ZM12.75 19V5H11.25V19H12.75Z" fill="#6B7280"/>
                    </svg>
                )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );

export default TableThead;
