import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { IPageParams } from '../api/IApi';
import { CONFIG } from '../config';

export interface IUserSliceInitState {
  listPageParams: IPageParams,
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState: {
    listPageParams: {
      page: 0,
      size: CONFIG.user.usersPerPage,
      q: '',
      sort: '',
      sortDir: 'asc',
    }
  } as IUserSliceInitState,

  reducers: {
    setUserlistPageParams: (state: IUserSliceInitState, action: PayloadAction<IPageParams>) => {
      state.listPageParams = action.payload;
    },
  },
});

// Reducers
export default userSlice.reducer;
export const {
  setUserlistPageParams,
} = userSlice.actions;

// Selectors
export const getUserlistPageParams = () => (state: RootState) => state.userSlice.listPageParams;
