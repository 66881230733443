import { FC, useEffect, useState } from "react";
import { MultiSelect, Option } from "react-multi-select-component";
import { ROLES, TRole } from "../../model/IRole";
import { IInput, TInputEvent } from "../form/Input";
import Label from "../form/Label";
// import './UserMultiSearchField.css';
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getVendorRoles } from "../../store/commonSlice";

const VendorRoleMultiSearchField: FC<IInput> = ({ label, handle, value, className, error }) => {
  const [selectedRoles, setSelectedRoles] = useState<Option[]>([]);
  const vendorRoles = useTypedSelector(getVendorRoles());
  
  // -- Convert selected users to string format for global state
  const convertVendorRolesToIdsStr = (roleOptions?: Option[]): string => (roleOptions || [])
    .map(({ value }) => value)
    .join(',');

  const getVendorRolesIdsArr = (): string[] => (vendorRoles || []).map(({ id }) => id);

  const convertVendorRolesIdsToRoles = (vRolesIdsStr: string): Option[] => {
    const vendorRolesIdsArr = getVendorRolesIdsArr();
    return vRolesIdsStr
      .split(',')
      .reduce((acc, roleId) => [
        ...acc,
        ...(vendorRolesIdsArr.find((rlId) => rlId === roleId)
          ? [{ label: roleId, value: roleId }]
          : []
        )
      ], [] as Option[]) || [];
  }

  // -- Apply global state changes to local selected list
  useEffect(() => {   
    setSelectedRoles(() => convertVendorRolesIdsToRoles(String(value)));
  }, [value]);
  
  return (
    <div className={`grid gap-1 ${className ?? ''}`}>
      <Label>{label}</Label>
      <MultiSelect      
        options={(vendorRoles || []).map(({ id }) => ({ label: id, value: id }))}
        value={selectedRoles}
        onChange={(e: React.SetStateAction<Option[]>) => {
          setSelectedRoles(e);
          handle && handle({ target: { value: convertVendorRolesToIdsStr(e as Option[]) }} as TInputEvent);
        }}
        labelledBy={label || 'Choose User Role'}
        valueRenderer={(selected, _options)=> selected.length
          ? selected.map(({ label }, i) => (
              <div
                role="button"
                tabIndex={-1}
                key={`option-selected-${label}-${i}`}
                className="truncate text-xs max-w-[70px] border-bg_dark border-[1px] bg-bg_light rounded-md py-[2px] px-1"
              >
                {label}
              </div>)
          )
          : "Choose Sale Reps"
        }
        className={`stylledScroll ${error ? 'border-pink-500 border-[1px] rounded-md' : ''}`}
        ArrowRenderer={() => 
          <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        }
        ClearSelectedIcon={(
          <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        )}
        ItemRenderer={({
          checked,
          option,
          onClick,
          disabled,
        }: {
          checked: boolean;
          option: Option;
          disabled?: boolean;
          onClick: () => {};
        }) => (
          <div
            style={{
              overflow: 'hidden',
              position: 'relative',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis', 
              display: 'flex',
              gap: '5px',
              alignItems: 'center',      
            }}
            className={`item-renderer ${disabled ? "disabled" : ""}`}
            role="switch"
            tabIndex={-1}
            onClick={onClick}
          >
            <div className="">
              {checked
                  ? (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_951_1356)">
                      <rect width="16" height="16" rx="4.26667" fill="#337AB7"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2313 3.97534C12.5395 4.17683 12.626 4.58999 12.4245 4.89815L7.89114 11.8315C7.7843 11.9949 7.61122 12.1032 7.41757 12.128C7.22392 12.1527 7.02916 12.0913 6.88471 11.9599L3.95138 9.29328C3.67894 9.04561 3.65886 8.62398 3.90653 8.35154C4.1542 8.0791 4.57584 8.05902 4.84827 8.30669L7.20306 10.4474L11.3085 4.16849C11.51 3.86032 11.9232 3.77385 12.2313 3.97534Z" fill="white"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_951_1356">
                      <rect width="16" height="16" rx="4.26667" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#18181B"/>
                    </svg>  
                  ) 
                }
            </div>
            <span>{option.label}</span>
          </div>
        )}        
      />
      {!!error && <div className="text-pink-600 text-sm mt-1">{error}</div>}
    </div>
  );
};

export default VendorRoleMultiSearchField;
