// import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import ILogin from '../model/ILogin';
import { IUser } from '../model/IUser';
import { ILoginState } from "../pages/SignIn.page";
import api /*, { transformResponse }*/ from './api';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<ILogin, ILoginState>({
      query: ({ login, password, token }) => ({
        url: 'oauth/login',
        method: 'POST',
        body: { login, password, token },
      }),
    }),

    logout: build.mutation<string, unknown>({
      query: () => ({
        method: 'POST',
        url: '/oauth/logout',
      }),
      // invalidatesTags: ['Rules'],
    }),

    me: build.query<IUser, unknown>({
      query: () => ({
        url: '/users/me',
      }),
    }),
  }),
});
