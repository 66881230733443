import { FC } from "react";
import { IActionLog } from "../../model/IActionLog";
import parseISOToString from "../../utils/parseISOToString";
import Marker from "../common/Marker";

const TicketActionLogItem: FC<IActionLog> = ({
  id,
  created,
  actionKey,
  actionText,
  ticketStatus,
}) => (
  <div className="flex flex-col py-2 px-4 bg-bg_light rounded-md">
    <div className="flex gap-2 items-center font-bold">
      <Marker status="offline" />
      {actionText || actionKey}
    </div>
    <div className="">{!!created && parseISOToString(created)}</div>
    <div className="">Status: {ticketStatus}</div>

  </div>

);

export default TicketActionLogItem;
