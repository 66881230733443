import { FC, PropsWithChildren } from "react";

const TicketFilterGroup : FC<PropsWithChildren<{ title?: string }>> = ({ title, children }) => (
  <div className="flex flex-col gap-1">
    {!!title && <div className="text-text_pale font-bold">{title}</div>}
    <div className="flex gap-1">
      {children}
    </div>    
  </div>
);

export default TicketFilterGroup; 
