import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getTicketPriorities } from "../../store/commonSlice";
import Input, { IInput } from "../form/Input";
import TicketPriority from "./TicketPriority";

const TicketPrioritySelect: FC<IInput> = (props) => {
  const priorities = useTypedSelector(getTicketPriorities()); 

  return (
    <Input
      options={priorities?.map(({ id, name }) => ({
          title: (
            <TicketPriority status={name} />),
          value: id
        })
      )}
      {...props}
      value={props?.value || priorities?.[0]?.id}
    />
  );
}

export default TicketPrioritySelect;
