import { Dispatch, FC, SetStateAction } from "react";
import Logo from "../common/loading/logo/Logo";
import { ILoginState } from "../../pages/SignIn.page";
import { useHandleField } from "../../hooks/useHandleField";
import Input from "../form/Input";
import Button from "../form/Button";

const SignInForm: FC<{ 
  state: ILoginState,
  stateErr?: Partial<Record<keyof ILoginState, string>>,
  setState: Dispatch<SetStateAction<ILoginState>>,
  loading?: boolean,
  onSignIn: () => unknown,
}> = ({
  state,
  stateErr,
  setState,
  loading,
  onSignIn,
}) => {

  const handleField = useHandleField(setState); 

  return (
    <>
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex justify-center">
        <Logo size="large" />
      </div>
      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-[400px]">
        <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
          <form className="space-y-6">        
            <Input
              label="Username"
              name="login"
              size="large"
              type="text"
              value={state.login}
              handle={handleField('login')}
              disabled={loading}
              error={stateErr?.login}
            />
            <Input
              label="Password"
              name="password"
              size="large"
              type="password"
              value={state.password}
              handle={handleField('password')}
              error={stateErr?.password}
              disabled={loading}
            />
            <div>
              <Button
                size="large"
                onClick={onSignIn}
                disabled={loading}
              >
                SIGN IN
              </Button>              
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SignInForm;