import { FC, ReactNode, useEffect, useState } from "react";
import { IPageParams } from "../../api/IApi";
import { ticketApi } from "../../api/ticket.api";
import { useTicket } from "../../hooks/useTicket";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useUser } from "../../hooks/useUser";
import { TRole } from "../../model/IRole";
import { getTicketPageParams, setTicketsPageParams } from "../../store/ticketSlice";
import parseISOToString from "../../utils/parseISOToString";
import Table, { ITableTh } from "../common/table/Table";
import Checkbox from "../form/Checkbox";
import TicketPriority from "./TicketPriority";
import TicketStatus from "./TicketStatus";
import { getTiketsTableFields, setFields, TTicketsTableColOption } from "../../store/ticketsFilterSlice";

export type TTicketTableCols = '' | 'Ticket #' | 'Priority' | 'Status' | 'Action Required By' | 'Type' | 'Customer' | 'Order #' | 'Vendor Action' | 'Vendor Order #' | 'Last Updated By' | 'Created' | 'Last Updated' | 'Assigned Vendor' | 'Assigned Agent';

const allTablesCols: Record<TTicketTableCols, ITableTh> = {
  '': { title: '' },
  'Ticket #': { title: 'Ticket #', sortCol: 'id' },
  'Priority': { title: 'Priority', sortCol: 'priority' },
  'Status': { title: 'Status', sortCol: 'status', align: 'center' },
  'Action Required By': { title: 'Action Required By', align: 'center' },
  'Type': { title: 'Type', sortCol: 'type' },
  'Customer': { title: 'Customer' },
  'Order #': { title: 'Order #', sortCol: 'orderId' },
  'Vendor Action': { title: 'Vendor Action' },
  'Vendor Order #': { title: 'Vendor Order #' },
  'Last Updated By': { title: 'Last Updated By' },
  'Created': { title: 'Created', sortCol: 'created' },
  'Last Updated': { title: 'Last Updated', sortCol: 'updated' },
  'Assigned Vendor': { title: 'Assigned Vendor', sortCol: 'assignedVendorUser' },
  'Assigned Agent': { title: 'Assigned Agent', sortCol: 'assignedUser' },
};

const tableColViews: { accessRoles: TRole[], cols: (keyof typeof allTablesCols)[] }[] = [
  {
    accessRoles: ['ROLE_ADMIN'],
    cols: [
      'Ticket #',
      'Status',
      'Action Required By',
      'Priority',
      'Type',
      'Customer',
      'Order #',
      'Vendor Order #',
      'Assigned Vendor',
      'Assigned Agent',
      'Last Updated By',
      'Created',
      'Last Updated',
      'Vendor Action',
    ]
  },
  {
    accessRoles: ['ROLE_VENDOR'],
    cols: [
      'Ticket #',
      'Status',
      'Action Required By',
      'Priority',
      'Type',
      'Customer',
      'Vendor Order #',
      'Assigned Vendor',
      'Order #',
      'Vendor Action',
      'Last Updated By',
      'Created',
      'Last Updated'
    ]
  },
  {
    accessRoles: ['ROLE_AGENT'],
    cols: [
      'Ticket #',
      'Status',
      'Action Required By',
      'Priority',
      'Type',
      'Customer',
      'Order #',
      'Vendor Order #',
      'Assigned Agent',
      'Last Updated By',
      'Created',
      'Last Updated',
      'Vendor Action',
    ]
  },
];

const TicketTable: FC = () => {
  const dispatch = useTypedDispatch();

  const { addTicketById, getTicketsFilterOptions } = useTicket();
  const { hasRoles } = useUser();
  const tableColsFilter = useTypedSelector(getTiketsTableFields());

  const [theadCols, setTheadCols] = useState<TTicketTableCols[]>();

  const ticketsPageParams = useTypedSelector(getTicketPageParams());
  const { data: tickets, isFetching } = ticketApi.useGetTicketsQuery({
    ...ticketsPageParams,
    ...getTicketsFilterOptions(),
  });


  // --
  // -- Determine thaad cols set depends on the currents roles
  // -- And set the calculated set to global state
  // --
  useEffect(() => {
    if (!tableColsFilter) {
      const theadCols = tableColViews.find(({ accessRoles }) => hasRoles(accessRoles))?.cols || [];

      setTheadCols(() => theadCols);
      dispatch(setFields(theadCols.reduce((acc, col) => ({
        ...acc,
        [col]: true,
      }), {} as TTicketsTableColOption)));
    }
  }, []);


  // --
  // -- If fields filter changes -> change table view
  // --
  useEffect(() => {
    tableColsFilter
      && Object.values(tableColsFilter)?.length
      && setTheadCols(() => Object.entries(tableColsFilter)
        .filter(([_, active]) => active)
        .map(([key, _]) => key) as TTicketTableCols[]);
  }, [tableColsFilter]);

  return !theadCols
    ? null
    : (
      <Table
        size="small"
        isPending={isFetching}
        pageParams={ticketsPageParams}
        pagable={tickets}
        updatePageParams={(newParams: Partial<IPageParams>) => dispatch(setTicketsPageParams({
          ...ticketsPageParams,
          ...newParams
        }))}
        thead={theadCols.map((colKey) => allTablesCols[colKey] || { title: '' })}
        tbody={
          tickets && tickets?.content?.length
            ? tickets.content.map((ticket) => {
              const allRowData: Record<keyof typeof allTablesCols, ReactNode> = {
                '': <Checkbox name="ticket" value={false} />,
                'Ticket #': ticket.id,
                'Priority': <TicketPriority status={ticket.priority.name} />,
                'Status': <TicketStatus statusId={ticket.status.id} />,
                'Type': ticket?.type?.name || '-',
                'Customer': ticket?.client?.name || '-',
                'Order #': ticket?.orderId || '-',
                'Vendor Action': ticket?.lastVendorAction?.actionText || '-',
                'Vendor Order #': ticket?.supplierOrderId || '-',
                'Last Updated By': ticket?.lastChangedBy?.name || '-',
                'Action Required By': ticket.actionRequiredBy || '-',
                'Created': parseISOToString(ticket.created),
                'Last Updated': ticket?.updated && parseISOToString(ticket.updated) || '-',
                'Assigned Vendor': ticket?.assignedVendorUser?.name || '-',
                'Assigned Agent': ticket?.assignedUser?.name || '-',
              };

              return {
                onClick: () => addTicketById(ticket.id),
                data: theadCols.map((colKey) => allRowData[colKey])
              }
            }) : []
        }
      />

    );
}

export default TicketTable;
