import { FC } from "react";
import { FileUploader } from "react-drag-drop-files";

const FilesUploader : FC<{ handleChange: (fileList: FileList) => unknown }> = ({ handleChange }) => (
  <FileUploader
    multiple
    handleChange={handleChange}
    name="msg-file-loader"
    children={(
      <div className="bg-slate-100 border-slate-300 border-dashed border-4 flex items-center justify-center text-slate-500 text-md h-32 rounded-lg">
        Upload or drop a file right here
      </div>
    )}
    // multiple={true}
    // types={["JPEG", "PNG", "GIF"]}
  />
); 

export default FilesUploader;
