import { FC, useEffect, useState } from 'react';
import { WrapInLayout } from '../layouts';
import SignInForm from '../components/oauth/SignInForm';
import { useAuth } from '../hooks/useAuth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { getCurrentUser } from '../store/authSlice';
import useSchemaSearchParams from '../hooks/useSerarchParams';

const initState = {
  login: '',
  password: '',
  token: '',
};
export type ILoginState = typeof initState;

const SignInPage: FC<{ originalUrl?: string }> = ({ originalUrl = '/' }) => {
  const user = useTypedSelector(getCurrentUser());
  const [loginState, setLoginState] = useState<ILoginState>(() => initState);
  const [loginErrState, setLoginErrState] = useState<Partial<Record<keyof ILoginState, string>>>();
  const [loading, setLoading] = useState<boolean>(false);
  const searchParams = useSchemaSearchParams();
  
  const navigate = useNavigate();
  const { handleLogin } = useAuth();

  // --
  // -- If the current user signed in go to original page
  useEffect(() => { 
    user 
    && navigate(searchParams.get('original-url') || originalUrl);
  }, [user]);
  
  const handleLogIn = async () => {
    setLoading(() => true);
    setLoginErrState(() => undefined);

    const errs = await handleLogin(loginState);

    errs
      ? setLoginErrState(() => errs)
      : navigate(originalUrl);    

    setLoading(() => false);
  }    

  return user === null 
    ? (
      <WrapInLayout title="Sign In" layout="center">
        <SignInForm
          state={loginState}
          stateErr={loginErrState}
          setState={setLoginState}
          loading={loading}
          onSignIn={handleLogIn}
        />
      </WrapInLayout> 
    ) : null;
};

export default SignInPage;
