import { FC } from "react";
import { icons } from "../../icons/IconSet";
import { IOrderItem } from "../../model/IOrder";
import Input, { IInput } from "../form/Input";

const OrderItemSelect: FC<IInput & {
  orderItems?: {
    id: IOrderItem['id'],
    pid: IOrderItem['pid'],
    name: IOrderItem['name'],
  }[],
}> = ({
  orderItems = [],
  ...props
}) =>  orderItems?.length > 1
  ? (
    <Input
      options={[
        {
          title: 'All Order Items',
          value: '',
        },
        ...orderItems?.map(({ id, pid, name }) => ({
            title: <>#{pid}, ({name})</>,
            value: id
          }))
      ]}      
      {...props}
    />
  ) : null;


export default OrderItemSelect;
