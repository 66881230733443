import { load } from "recaptcha-v3";

export default async function generateRecaptchaV3(action?: string): Promise<string> { 
  try {
    const recaptcha = await load(process.env.REACT_APP_RECAPTCHA_KEY || '');
    const tkn = await recaptcha.execute(action || '');

    return tkn
      ? String(tkn)
      : '';
  } catch (err) {   
    return '';
  }
}