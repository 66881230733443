import { FC } from "react";
import { useTicket } from "../../hooks/useTicket";
import PageHeader from "../../layouts/dashboard/PageHeader";
import TicketFilterPannel from "./TicketFilterPannel";
import TicketTab from "./TicketTab";

const TicketHeader: FC = () => {
  const { activeTabId, isActiveTabNew, activeTicketTab } = useTicket();

  return (
    <div className="flex gap-2 items-center">
      {/* Tikets table */}
      {(!activeTicketTab && <TicketFilterPannel />)
      || (activeTabId && isActiveTabNew && <PageHeader>Create New CS Ticket</PageHeader>)
      || (activeTicketTab && (
          <TicketTab
            id={activeTicketTab.id}
            userName={activeTicketTab.userName}
            statusId={activeTicketTab.statusId}
          />))
      || null}
    </div>
  );
};

export default TicketHeader;
