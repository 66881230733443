import { FC } from "react";
import DropMenu from "../common/dropmenu/DropMenu";
import { icons } from "../../icons/IconSet";
import { useConfirm } from "../../hooks/useConfirm";
import { useUser } from "../../hooks/useUser";
import toast from "react-hot-toast";
import { msg } from "../../msg";
import { useNavigate } from "react-router-dom";
import { useAccess } from "../../hooks/useAccess";

const UserActionMenu: FC<{ userId: string, userEmail?: string }> = ({ userId, userEmail }) => {  
  const confirm = useConfirm();
  const { deleteUser } = useUser();
  const navigate = useNavigate();
  const { getSettingAccess } = useAccess();

  // -- Handlers
  const handleDelete = () => {   
    // -- Check permissions
    if (!getSettingAccess('delete-user')) return navigate('/users');
    
    confirm.on({
      text: `Please confirm that you want to delete this user ${userEmail ? `(${userEmail})` : ''}`,
      action: async () => (await deleteUser(userId)) && toast.success(msg('user-deleted')),
      applyText: 'Delete',
    });
  }

  return (
    <div className="flex items-center">
      <DropMenu
        width="110px"
        top="20px"
        right="6px"
        icon={icons('ellipsis-vertical', { width: '18px' })}
        links={[
          ...(!getSettingAccess('edit-user') ? [] : [{
            name: (
              <div className='flex gap-2 items-center'>
                {icons('edit', { width: '16px' })}
                Edit
              </div>),
            onClick: () => navigate(`/users/${userId}`)
            
          }]),
          ...(!getSettingAccess('delete-user') ? [] : [{
            name: (
              <div className='text-red-500 flex gap-2 items-center'>
                {icons('delete', { width: '16px' })}
                Delete
              </div>),
            onClick: handleDelete
          }]),
        ]}
      />
    </div>
  );
}

export default UserActionMenu;
