import { FC } from "react";
import { IActionLog } from "../../model/IActionLog";
import TicketActionLogItem from "./TicketActionLogItem";

const TicketActionLog: FC<{ 
  title?: string,
  logItems?: IActionLog[]
}> = ({
  title,
  logItems,
}) => (
  <div className="">
    {!!title && <div className="text-md font-bold pb-3">{title}</div>}
    {(logItems || []).map((logItem) => <TicketActionLogItem key={logItem.id} {...logItem} />)}
  </div>
);

export default TicketActionLog;
