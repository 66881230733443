import { FC } from "react";
import { IMessageAttachment } from "../../model/IMessage";
import { NavLink } from "react-router-dom";
import { icons } from "../../icons/IconSet";

const LoadedFileList: FC<{ 
  files: IMessageAttachment[],
  handleDeleteAttachment?: (id: number) => unknown,
  disabled?: boolean,
}> = ({ 
  files,
  handleDeleteAttachment,
  disabled = false,
}) => !files?.length
  ? null
  : (
    <div className={`
      flex flex-wrap 
      ${handleDeleteAttachment
        ? 'gap-5'
        : 'gap-2'
      }
    `}>
      {files.map(({ id, name, path }) => (
        <div
          className="relative"          
          key={`loaded-file-${name}`}
        >
          <NavLink
            to={path}
            className="text-primary underline hover:text-primary_dark py-2 px-3 rounded-md bg-white shadow"
            target="_blank"
          >
            {name}            
          </NavLink>
          {!!handleDeleteAttachment && (
              <div
                className="absolute right-[-7px] top-[-10px] bg-slate-400 hover:bg-slate-500 transition-all text-white rounded-[50%] p-[2px] cursor-pointer shadow-md"
                tabIndex={-1}
                role="button"
                onClick={(e) => {
                  e.stopPropagation();                  
                  if (disabled) return;
                  handleDeleteAttachment(id);
                }}
              >
                {icons('x', { width: '12px' })}
              </div>
            )}
        </div>
      ))}
    </div>
  )

export default LoadedFileList;
