import { FC } from "react";
import { IGetUsersRequest, IUserScope, userApi } from "../../api/user.api";
import { icons } from "../../icons/IconSet";
import Input, { IInput } from "../form/Input";
import { TRole } from "../../model/IRole";
import { IUser } from "../../model/IUser";

const UserSelect: FC<IInput & {
  excludeRoles?: TRole[],
  onlyRoles?: TRole[],
  scope?: IUserScope,
}> = (props) => {
  const { data: users, isFetching } = userApi.useGetUsersQuery({ searchStr: '', scope: props.scope });
  
  const filterUsers = (users?: IUser[]) => (users || [])
    .filter(({ userRole }) => {
      if (props?.onlyRoles?.length) return !!(userRole || []).find(({ role }) => props.onlyRoles?.includes(role));
      if (props?.excludeRoles?.length) return !!!(userRole || []).find(({ role }) => props.excludeRoles?.includes(role));
      return true;
    });

  return (
    <Input
      options={filterUsers(users)?.map(({ id, name, userRole, email, vendorRole }) => ({
          title: (
            <div className="flex gap-2 items-center overflow-hidden">
              <div className="bg-gray-400 rounded-[50%] p-[1px]">
                {icons('user-round', { width: '16px', stroke: 'white' })}
              </div>
              <div className="whitespace-nowrap overflow-hidden text-ellipsis">                      
                {name || email || 'Unknown'}&nbsp;
                {vendorRole?.[0]
                  ? `(${vendorRole[0].role.id})`
                  : email
                }
              </div> 
            </div>),
          value: id
        })
      )}      
      {...props}
      disabled={props.disabled || isFetching}
    />
  );
}

export default UserSelect;
