import { FC, PropsWithChildren } from "react";

const RotateIcon: FC<PropsWithChildren<{ isShown?: boolean }>> = ({ children, isShown = false }) => (
  <div className={`
    transition-all duration-300
    ${isShown
      ? 'rotate-180'
      : 'rotate-0'}
  `}>
    {children}
  </div>
);

export default RotateIcon;
