interface String {
  capitalizeAll(): string;
  capitalize(): string;
}

String.prototype.capitalize = function () {
  return (this.toString()?.[0] ?? '').toLocaleUpperCase() + (this.toString() ?? '').substring(1);
}

String.prototype.capitalizeAll = function () {
  return (this.toString() || '')
    .split(/\s+/)
    .map(word => word.capitalize())
    .join(' ');
};

