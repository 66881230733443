export const ROLES = {
  ROLE_USER: 'User',
  ROLE_AGENT: 'Agent',
  ROLE_VENDOR: 'Vendor',
  ROLE_ADMIN: 'Admin',
};

export type TRole = keyof typeof ROLES;

export interface IRole {
  role: TRole,
  notes: string,
  authority: string,
}