const msgs = {
  'not-enough-data': 'Not enough data',
  'unknown-error': 'Unknown error',

  'login-field-empty':  'Please enter your login!',
  'login-field-no-email': 'Your login should be an email!',
  'password-field-empty': 'Please enter your password!',
  'password-field-too-short': 'Your password is too short!',
  'token-empty': 'You looks like a robot!',
  'empty-recaptcha': 'Faild to generate ReCaptcha',
  
  'signin-failed': 'Signing in failed',
  'signin-wrong-data': 'Invalid username or password',

  'load-orders-filed': 'Failed to load orders',
  'empty-order-id': 'Empty order Id',
  'empty-message': 'Empty message',

  'failed-to-add-ticket': 'Failed to add ticket',
  'failed-to-get-tickets': 'Failed to get tickets {{info}}',
  'success-ticket-adding': 'The ticket was added successfully!',
  'failed-to-edit-ticket': 'Failed to edit ticket!',
  'success-ticket-editing': 'The ticket was edited successfully!',
  
  'failed-to-delete-ticket-message': 'Failed to delete ticket message',
  'not-permissions-to-delete-msg': "You don't have enough permissions for delete this message",
  'ticket-msg-deleted': "The ticket message has been deleted successfully",
  'not-permissions-to-edit-msg': "You don't have enough permissions for edit this message",
  'failed-to-edit-ticket-message': 'Failed to edit ticket message',
  'ticket-msg-attachment-deleted': "The ticket message attachment has been deleted successfully",

  'user-search': 'Faild to search an user', 
  'user-list': 'Faild to load a user list', 
  'user-details': 'Faild to load user details', 
  'user-email-unsuitable': 'Wrong email format',
  'user-no-email': 'Please enter client email',
  'user-short-password': 'Password is too short',
  'user-create-failed': 'Failed to create the user',
  'user-password-no-match': 'Passwords don\'t match',
  'user-delete-failed': 'Failed to delete the user',
  'user-edit-failed': 'Failed to edit the user',
  'user-main-updated': 'Failed to uptade the user information',
  'user-pass-updated': 'Failed to uptade the user passwords',
  'user-updated-himself': 'Your information hasn\'t been updated',
  'user-no-current-password': 'Your have to enter current password',
  'user-deleted':       'User was deleted successfully',
  'user-create':        'User was added!',
  'user-edited':        'User was edited!',
  'user-no-roles':      'User should have at least one role!',

  'ticket-message-added':      'Ticket message was added successfully!',
  'empty-message-recipient':    'Select a message recipient',
  'ticket-was-refreshed': 'The ticket was refreshed successfully',
  'failed-to-refresh-ticket': 'Failed to refresh the ticket',

  'failed-to-perform-action': 'Failed to perform ticket action',
  'action-was-performed': 'Ticket action was performed successfully!',
  'no-action': 'The action not found',
  'no-action-text': 'Action message is required',
  'no-action-file': 'File message is required'
};

export const msg = (key: keyof typeof msgs, options?: Record<string, string>): string => 
  options
    ? Object.entries(options).reduce((acc, [key, value]) =>
      acc.replaceAll(`{{${key}}}`, value), msgs[key])
    : msgs[key]; 
