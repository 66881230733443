import { FC } from "react";
import { useCommonData } from "../../hooks/useCommonData";
import { useTicket } from "../../hooks/useTicket";
import { icons } from "../../icons/IconSet";
import Button from "../form/Button";
import TicketTab from "./TicketTab";
import Marker from "../common/Marker";

const TicketTabs: FC = () => {
  const {
    ticketTabs,
    activeTabId,
    isActiveTabNew,
    handleNewTicketTab,
    handleSetActiveTabId,
    handleRemoveActiveTabId,
    handleRemoveTicketTabById,
  } = useTicket();

  const { getTicketStatusById, getTicketTypeById } = useCommonData();

  return (
    <div className="flex">      
      <div
        tabIndex={-1}
        role="button"
        onClick={handleRemoveActiveTabId}
        className={`
          flex items-center gap-4 bg-bg_light px-3 border-r-[1px] border-border_color hover:opacity-100 cursor-pointer transition-all duration-200
          ${!activeTabId
            ? 'border-b-[1px] h-[calc(100%+1px)] border-b-bg_light'
            : 'opacity-50'
          }        
        `}
      >
        {!activeTabId && <Marker />}        
        {icons('home', { width: '16px' })}
      </div>

      {(ticketTabs || []).map(({ id, typeId, statusId }) => (
        <div
          tabIndex={-1}
          role="button"
          onClick={handleSetActiveTabId(id)}
          key={`ticket-tab-${id || Math.random()}`}
          className={`            
            relative flex items-center gap-4 bg-bg_light px-3 border-r-[1px] border-l-[1px] border-border_color
            ${id === activeTabId
              ? 'border-b-[1px] h-[calc(100%+1px)] border-b-bg_light'
              : 'opacity-50'
            }
          `}
        >
          {id === activeTabId && (
            <div className="shrink-0">
              <div className="bg-primary rounded-[50%] w-[10px] h-[10px]" />
            </div>
          )}
          <div className="flex flex-col justify-center overflow-hidden leading-5">
            { id && id?.includes('new')
              ? <div className="font-bold truncate">New Ticket</div>
              : (
                <>
                  <div className="font-bold truncate">
                    {!!typeId && (getTicketTypeById(typeId)?.name || '')}:&nbsp;
                    {!!statusId && (getTicketStatusById(statusId)?.name || '')}
                  </div>
                  <div className="truncate">Ticket #{id}</div>
                </>
              )
            }
          </div>
          <div
            tabIndex={-1}
            role="button"
            className="flex items-center shrink-0"
            onClick={(e) => {              
              e?.stopPropagation();
              handleRemoveTicketTabById(id)();
            }}
          >
            {icons('x', { width: '14px'})}
          </div>
        </div>
      ))}

      <div className="flex items-center px-4">
        <Button
          variant="outline"
          size="tiny"
          className={`
            flex gap-2 items-center
            ${isActiveTabNew
              ? 'opacity-50'
              : ''
            }
          `}
          onClick={handleNewTicketTab}
        >
          {icons('plus', { width: '12px'})}
          Add Ticket
        </Button>
      </div>
      
    </div>
  );
};

export default TicketTabs;
