import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { INavItem } from "../../nav/INav";
import RotateIcon from "../RotateIcon";
import { icons } from "../../../icons/IconSet";

export type IDropMenuLink = INavItem & {
  isHideMenu?: boolean,
};

export interface IDropMenuOptions {
  isShown?: boolean,
  width?: string,
  top?: string,
  right?: string,
  left?: string,
  bottom?: string,
  icon?: React.ReactNode,
  direction?: 'top' | 'bottom',
};

const DropMenu : FC<PropsWithChildren<IDropMenuOptions & {
  links?: IDropMenuLink[],
  content?: ReactNode,
  subMenuOptions?: IDropMenuOptions,
}>> = ({
  isShown: isShownDefault = false,
  children,
  links,
  content,
  width,
  top,
  right,
  left,
  bottom,
  icon,
  direction = 'bottom',
  subMenuOptions,
}) => {
  const [isShown, setIsShown] = useState<boolean>(isShownDefault); 

  const outClick = () => setIsShown(() => false);
  document.addEventListener("click", outClick);  
  useEffect(() => document.removeEventListener("click", outClick), []); 

  return (
    <div className="relative">
      <div
        role="button"
        tabIndex={-1}
        onClick={(e) => {
          e.stopPropagation();
          setIsShown((prev) => !prev);
        }}
        className={(!links?.length && !content )? 'opacity-50' : ''}
      >
        {icon
          ? <RotateIcon isShown={isShown}>{icon}</RotateIcon>
          : <>{children}</>}
      </div>
      <div
        style={{
          position: 'absolute',
          ...(width ? { width } : {}),
          ...(top ? { top } : { }),
          ...(right ? { right } : {}),
          ...(left ? { left } : {}),
          ...(bottom ? { bottom } : {}),
        }}
        className={`transition-all duration-300
          ${{
            'top': '',
            'bottom': '',
          }[direction]} 
          ${isShown
            ? 'opacity-100 translate-y-1 z-20'
            : 'opacity-0 -translate-y-0 -z-10'}
          ${width ? '' : 'w-[150px]'}
        `}
        tabIndex={-1}
        role="button"
        onClick={(e) => e.stopPropagation() }
      >            
      <div className="relative w-full shrink rounded-md py-2 bg-white leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
        {(links || []).map(({ name, to, onClick, children, isHideMenu }) => 
          (!name && <div
              key={Math.random()}
              className="bg-slate-200 h-[1px] w-full my-1"
            />)
          || (to && <NavLink
                key={`${to}-${name}`}
                to={to}
                onClick={() => {
                  onClick && onClick();
                  isHideMenu && setIsShown(() => false);
                }}
                className="block py-2 hover:text-primary hover:bg-slate-50 px-4"
              >
                {name || '-'}
              </NavLink>)
          || <span
                key={Math.random()}
                role="button"
                tabIndex={-1}
                onClick={() => {
                  onClick && onClick();
                  isHideMenu && setIsShown(() => false);
                }}
                className="block py-2 hover:text-primary hover:bg-slate-50 px-4"
              >
                {!children
                  ? name || '-'
                  :                        
                    <DropMenu
                      {...(subMenuOptions || {})} 
                      links={children}
                    >
                      <div className="flex justify-between items-center">
                        {name}
                        {icons('chevron-right', { width: '1em' })}
                      </div>
                    </DropMenu>
                }                  
            </span>
        )}
        {!!content && content}
      </div>
      </div>        
    </div>    
  );
}

export default DropMenu;
