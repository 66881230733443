import { FC } from "react";

interface ITicketPrioritySettings {
  title: string,
  color: string,
}
const ticketTypes: Record<string, ITicketPrioritySettings> = {
  'Urgent': { title: 'Urgent', color: '#E11818' },
  'High': { title: 'High', color: '#E17918' },
  'Medium': { title: 'Medium', color: '#000000' },
  'Low': { title: 'Low', color: '#545454' },
};

const TicketPriority: FC<{
  status: string,
  isIcon?: boolean,
  name?: string,
}> = ({
  status = 'urgent',
  isIcon = false,
  name,
}) => isIcon
  ? (
    <div
      style={{ background: ticketTypes?.[status]?.color || '' }}
      className="rounded-[50%] w-[10px] h-[10px]"
    />
  ) : (
    <div
      style={{ color: ticketTypes?.[status]?.color || '' }}
      className="font-bold"
    >
      {name ?? ticketTypes?.[status]?.title ?? status}
    </div>
  );

export default TicketPriority;
