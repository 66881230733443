import { FC } from "react";
import TicketFilterGroup from "./TicketFilterGroup";
import Button from "../form/Button";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getPredefined, switchOnPredefined } from "../../store/ticketsFilterSlice";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import TicketsTableColsFilter from "./TicketsTableColsFilter";
import Divider from "../common/Divider";
import TicketTypesFilter from "./TicketTypesFilter";
import TicketsDateFilter from "./TicketsDateFilter";
import TicketSearchField from "./TicketSearchField";
import { useAccess } from "../../hooks/useAccess";

const TicketFilterPannel : FC = () => {
  const dispatch = useTypedDispatch();
  const predefined = useTypedSelector(getPredefined());
  // const { getSettingAccess } = useAccess();

  return (
    <div className="flex flex-col gap-1 w-full">
      <strong>Filter by</strong>
      <div className="flex justify-between w-full">
        <div className="flex gap-4 items-end">
          <TicketFilterGroup title="Predefined">
            {!!predefined?.length && predefined.map(({ title, isActive }) => (
              <Button
                key={`predefined-option-${title}`}
                isNotFullWidth
                type={isActive ? 'submit' : 'button'}
                className="whitespace-nowrap flex gap-1 items-center font-normal pl-3 pr-3 pt-1 pb-1"
                onClick={() => dispatch(switchOnPredefined({ title, active: !isActive }))}
              >
                {title}
              </Button>
            ))}          
          </TicketFilterGroup>
          
          {/* {!!getSettingAccess('ticket-asigned-agent') && (
            <TicketFilterGroup title="Assigned to Agent">                 
            </TicketFilterGroup>
          )} */}         

          <Divider vertical height="h-[2em]" />
          <TicketFilterGroup title="Fields">
            <TicketsTableColsFilter />
            <TicketTypesFilter />
          </TicketFilterGroup>

          <Divider vertical height="h-[2em]" />
          <TicketFilterGroup title="Date">
            <TicketsDateFilter />
          </TicketFilterGroup>
        </div>

        <div className="flex">
          <TicketFilterGroup title="Search">
            <TicketSearchField />
          </TicketFilterGroup>
        </div>
      </div>
    </div>
  );
};

export default TicketFilterPannel; 
