import { IMessage } from "../model/IMessage";
import { TRole } from "../model/IRole";
import { pagesAccess, settingAccess } from "../routing/accessMap";
import { getCurrentUser } from "../store/authSlice";
import { useTypedSelector } from "./useTypedSelector";

export function useAccess() {
  const user = useTypedSelector(getCurrentUser());

  const ArrayWithoutRepeats = <T>(arr: Array<T>): Array<T> =>
    Array.from(new Set([...arr]));

  const getRoleTypes = (usr = user): TRole[]  => usr && usr?.userRole?.length
    ? ArrayWithoutRepeats(usr.userRole.map(({ role }) => role))
    : [];

  const getAccess = <T extends string | number | symbol>(accessMap: Record<T , string[]>) => (optionName: T): boolean => {
    const allowedRoleTypes = ArrayWithoutRepeats(accessMap?.[optionName] || []);
    const currentRoleTypes = getRoleTypes();
      
    return new Set([...allowedRoleTypes, ...currentRoleTypes]).size < (allowedRoleTypes.length + currentRoleTypes.length);
  }

  // --
  // -- Ticket Access
  // --
  const getTicketMsgDeleteAccess = (message: IMessage, checkUser = user) => message.fromUser?.id === checkUser?.id;
  const getTicketMsgEditAccess = (message: IMessage, checkUser = user) => message.fromUser?.id === checkUser?.id;


  return {
    getPageAccess: getAccess(pagesAccess),
    getSettingAccess: getAccess(settingAccess),

    getTicketMsgDeleteAccess,
    getTicketMsgEditAccess,
  };
}
