import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import { store } from './store';
import './utils/string.extensions';
import ConfirmProvider from './hooks/useConfirm';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <ConfirmProvider>
        <Toaster />
        <App />
      </ConfirmProvider>
    </HelmetProvider>
  </Provider>
);
