import { FC, useEffect, useState } from "react";
import { icons } from "../../icons/IconSet";
import Button from "../form/Button";
import { ITicket } from "../../model/ITicket";
import { useTicket } from "../../hooks/useTicket";
import toast from "react-hot-toast";
import { msg } from "../../msg";
import useSchemaSearchParams from "../../hooks/useSerarchParams";
import Modal from "../common/Modal";
import { ITicketStateAction } from "../../model/ITicketState";
import TicketMsg from "../tickets/TicketMsg";
import DropMenu from "../common/dropmenu/DropMenu";

export type ITicketFullAction = ITicketStateAction & {
  text: string,
  attachments: File[],
}

const TicketAction: FC<{ 
  mode?: 'dropDown' | 'buttons',
  ticket?: ITicket,
  loading?: boolean,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({ 
  ticket,
  loading,
  setLoading,
  mode = 'buttons',
}) => {
  const { performAction } = useTicket();
  const params = useSchemaSearchParams()
  const [fullCurrentAction, setFullCurrentAction] = useState<ITicketFullAction>();
  const [fullCurrentActionErr, setFullCurrentActionErr] = useState<Partial<Record<keyof ITicketFullAction, string>>>();
  const actionKeyFromAvaliable = (searchKey?: string): ITicketStateAction | undefined => 
    (ticket?.state?.availableActions || []).find(({ key }) => key === searchKey);

  const sendAction = (actionState?: ITicketFullAction) => async () => {
    if (!ticket?.id || !actionState) return false;

    setLoading && setLoading(() => true);
    setFullCurrentActionErr(() => undefined);

    const errs = await performAction(ticket?.id, actionState);

    if (!errs) {
      toast.success(msg('action-was-performed'));
      setFullCurrentAction(() => undefined);
      setFullCurrentActionErr(() => undefined);
    } else
      setFullCurrentActionErr(() => errs);

    setLoading && setLoading(() => false);
  };

  const handlePerformAction = async (actionKey: string) => {
    if (!ticket?.id || !actionKey) return false;

    const action = actionKeyFromAvaliable(actionKey);
    if (!action) {
      toast.error(msg('no-action'));      
      return false;
    }

    const fullAction = {
      ...action,
      text: '',
      attachments: [],
    };

    action.type === 'simple'
      ? sendAction(fullAction)() // -- Send the action without any additional info
      : setFullCurrentAction(() => fullAction);  // -- Open Modal with additional forms
  }; 

  useEffect(() => {    
    params.get('ticketAction') && (async () => {
      await handlePerformAction(params.get('ticketAction') || '');
      params.delete('ticketAction').push();
    })();
  }, []);

  
  return !ticket?.state?.actionRequried
    ? null
    : (
      <>
        {mode === 'dropDown'
          ? (
            <DropMenu
              width="238px"
              right="-0px"
              bottom="50px"
              subMenuOptions={{
                width: "200px",
                right: "-220px",
                top: "-10px",
              }}
              links={(ticket?.state?.availableActions || []).map(({ key, name }) => ({ 
                name,
                onClick: () => handlePerformAction(key) 
              }))}
            >
              <Button
                isNotFullWidth
                disabled={loading}
                type="button"
                size="small"
                className="whitespace-nowrap flex gap-2 items-center"
              >
                {icons('circle-fading-plus', { width: '18px' })}
                Add Action
              </Button>
            </DropMenu>
          ) : (
            <div className="bg-[#EBF1FA] p-4 flex flex-col gap-3 m-4 rounded-md">
              <div className="flex gap-2 text-black font-bold items-center">
                {icons('triangle-alert', { stroke: '#304B73' })}
                Action Required        
              </div>
              {ticket?.state?.actionMessage && (
                <div className="">
                  {ticket?.state?.actionMessage}
                </div>
              )}
              <div className="flex gap-4">
                {(ticket?.state?.availableActions || []).map(({ key, name }) => (
                  <Button
                    key={key}
                    isNotFullWidth
                    type="submit"
                    size="small"
                    disabled={loading}
                    className="whitespace-nowrap flex gap-2 items-center"
                    onClick={() => handlePerformAction(key)}
                  >
                    {name}
                  </Button>))
                }
              </div>
            </div>
          ) 
        }
        {!!fullCurrentAction && (
          <Modal
            title={(
              <div className="text-lg flex font-bold">
                Action: {fullCurrentAction?.name || ''}
              </div>
            )}
            style={{ maxWidth: '600px' }}
            onClose={() => setFullCurrentAction(() => undefined)}
          >
            <TicketMsg
              name="text"
              value={fullCurrentAction.text}
              handle={(e) => setFullCurrentAction((prevState) => prevState
                ? ({ 
                  ...prevState,
                  text: e.target.value,
                }) : undefined,              
              )}
              error={fullCurrentActionErr?.text}
              buttonTitle={fullCurrentAction?.name || ''}
              handleClick={sendAction(fullCurrentAction)}            
              disabled={loading}
              filesState={fullCurrentAction.attachments}
              setFileState={(files: File[]) => setFullCurrentAction((prevState) => prevState
                ? ({
                  ...prevState,
                  attachments: files,
                }) : undefined
            )}
            />
          </Modal>
        )}
      </>
    );
};

export default TicketAction;
