import { FC } from "react";
import Input, { IInput } from "../form/Input";
import { icons } from "../../icons/IconSet";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { getVendors } from "../../store/commonSlice";

const VendorSelect: FC<IInput> = (props) => {
  const vendorList = useTypedSelector(getVendors());

  return (
    <Input
      options={[
        // { title: "Don't attach to a vendor", value: '' },
        ...(vendorList ||[])?.map(({ id, agentName, name }) => ({
            title: (
                <div className="flex gap-2 items-center">
                  <div className="bg-gray-400 rounded-[50%] p-[1px]">
                    {icons('zap', { width: '16px', stroke: 'white' })}
                  </div>        
                  {agentName} ({name})
                </div>),
            value: String(id)
          })
      )]}      
      {...props}
    />
  );
}

export default VendorSelect;
