import { FC, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useHandleField } from '../../hooks/useHandleField';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useUser } from '../../hooks/useUser';
import { IEditUser, IEditUserPassword, IUser, IUserRequest } from '../../model/IUser';
import { msg } from '../../msg';
import { getVendors } from '../../store/commonSlice';
import { IZodErrorsState } from '../../utils/checkFormStateByZod';
import Button from '../form/Button';
import Checkbox from '../form/Checkbox';
import FormTitle from '../form/FormTitle';
import Input from '../form/Input';
import UserRoleMultiSearchField from '../user/UserRoleMultiSearchField';
import VendorRoleMultiSearchField from '../vendor/VendorRoleMultiSearchField';
import VendorSelect from '../vendor/VendorSelect';
import { useAccess } from '../../hooks/useAccess';
import AgentRoleMultiSearchField from '../agent/AgentRoleMultiSearchField';

const UserEditForm: FC<{ user: IUser }> = ({ user }) => {
  const { editMainUserInfo, editPassUserInfo } = useUser();
  const navigate = useNavigate();
  const vendorList = useTypedSelector(getVendors());
  const { convertRolesToIdsStr, convertVendorFullRolesToRolesStr, convertAgentFullRolesToRolesStr } = useUser();
  
  // -- Check permissions
  const { getSettingAccess } = useAccess();
  useEffect(() => {
    !getSettingAccess('edit-user') && navigate('/users');
  }, []);

  // -- Init form state
  const initState: IEditUser = {
    name: user?.name || '',
    phone: user?.phone || '',
    isActive: !!user?.active ?? true,
    roles: user?.userRole?.length && convertRolesToIdsStr(user.userRole) || 'ROLE_USER',
    vendor: String(user?.vendorRole?.[0]?.vendor?.id) || '',
    vendorRole: convertVendorFullRolesToRolesStr(user?.vendorRole),
    agentRole: convertAgentFullRolesToRolesStr(user?.agentRole),
  };

  const initPasswordState: IEditUserPassword = {
    password: '',
    passwordConfirm: '',
  }

  // -- States
  const [userState, setUserState] = useState<IEditUser>(initState);
  const [userErrState, setUserErrState] = useState<Partial<IZodErrorsState<IUserRequest>>>();  
  const [userStatePassword, setUserStatePassword] = useState<IEditUserPassword>(initPasswordState);
  const [userErrStatePassword, setUserErrStatePassword] = useState<Partial<IZodErrorsState<IEditUserPassword>>>();
  const [isPending, setIsPending] = useState<boolean>(() => false);

  // -- Fill default vendor
  useEffect(() => {
    vendorList?.length && !userState?.vendor && setUserState((prevState) => ({
      ...prevState,
      vendor: String(vendorList?.[0]?.id) || '',
    }));
  }, [vendorList]);
  
  const handle = useHandleField(setUserState);
  const handlePswrd = useHandleField(setUserStatePassword);
  const handleCheckbox = (key: Partial<keyof typeof initState>) => () => 
    setUserState((prevState) => ({
      ...prevState,
      [key]: !!!prevState?.[key],
    }));

  const handleEditUser = async () => {
    setIsPending(() => true);
    setUserErrState(() => undefined);

    const errs = await editMainUserInfo(user.id, userState);          
    if (errs) {
      setUserErrState(() => errs);
      setIsPending(() => false);
      return;
    }
    
    toast.success(msg('user-edited'));      
    return navigate('/users'); 
  }

  const handleEditUserPass = async () => {    
    setIsPending(() => true);
    setUserErrStatePassword(() => undefined);

    const errs = await editPassUserInfo(user.id, userStatePassword);          
    if (errs) {      
      setUserErrStatePassword(() => errs);
      setIsPending(() => false);
      return;
    }

    toast.success(msg('user-edited'));      
    return navigate('/users'); 
  }

  return (
    <div className="w-full grid grid-cols-12 ">
      <div className="p-4 col-span-12 lg:col-span-6 border-border_color border-b-[1px] pb-6">
          <FormTitle>Main User Info</FormTitle>
          <div className='grid grid-cols-12 gap-x-10 gap-y-6 items-center'>
            <Input
              label="Email"
              name="email"              
              value={user?.email || ''}              
              className='col-span-6'
              disabled
            />         
            <Checkbox
              label="Enable"
              name="active"
              value={userState.isActive ?? false}
              handleToggle={handleCheckbox('isActive')}
              className='col-span-3'
              disabled={isPending}
            />   
            <Input
              label="Name"
              name="name"
              placeholder='Manager name'
              value={userState.name}
              handle={handle('name')}
              className='col-span-6'
              error={userErrState?.name}
              disabled={isPending}
            />
            <Input
              label="Phone"
              name="phone"
              placeholder='Manager phone'
              value={userState.phone}
              handle={handle('phone')}
              className='col-span-6'
              error={userErrState?.phone}
              disabled={isPending}
            />            
          </div>
      </div>
      <div className="p-4 col-span-12 lg:col-span-6 border-border_color border-l-[1px] border-b-[1px] pb-6 bg-bg_light">
        <FormTitle>Role Info</FormTitle>
        <div className="grid grid-cols-12 gap-x-10 gap-y-10">
          <UserRoleMultiSearchField
            label="Roles"
            name="roles"
            placeholder='User role'
            value={userState.roles}
            handle={handle('roles')}
            className='col-span-12'
            error={userErrState?.roles}
            disabled={isPending}
          />

          {(userState?.roles || '').includes('ROLE_VENDOR') && (
            <div className='col-span-12 flex flex-col gap-4'>
              <VendorSelect
                label="Vendor"
                name="vendor"
                placeholder='Attach to Vendor'
                value={userState.vendor}
                handle={handle('vendor')}
                error={userErrState?.vendor}
                disabled={isPending}
              />
              {!!userState.vendor && (
                <VendorRoleMultiSearchField
                  label="Vendor Role"
                  name="vendorRole"
                  placeholder='Role for Vendor'
                  value={userState.vendorRole}
                  handle={handle('vendorRole')}
                  className='col-span-12'
                  error={userErrState?.vendorRole}
                  disabled={isPending}
                />
              )}                
            </div>
          )}

          {(userState?.roles || '').includes('ROLE_AGENT') && (
            <div className='col-span-12'>
              <AgentRoleMultiSearchField
                label="Agent Role"
                name="agentRole"
                placeholder='Role for Agent'
                value={userState.agentRole}
                handle={handle('agentRole')}
                error={userErrState?.agentRole}
                disabled={isPending}
              />
            </div>
          )}          

        </div>      
      </div>
      <div className="flex justify-end gap-4 mt-6 col-span-12 p-4">
          <Button
            onClick={() => setUserState(() => initState)}
            variant="outline"
            size='small'
            isNotFullWidth
            disabled={isPending}
          >
            Reset From
          </Button>
          <Button
            onClick={handleEditUser}
            size='small'
            isNotFullWidth
            disabled={isPending}
          >
            Edit User
          </Button>            
      </div>

      <div className="grid justify-end gap-4 mt-6 col-span-12 grid-cols-12 p-4 border-border_color border-t-[1px]">        
        <Input
          label='Password (required)'
          name="password"
          value={userStatePassword.password}
          handle={handlePswrd('password')}
          type="password"
          className='col-span-6'
          error={userErrStatePassword?.password}
          disabled={isPending}
        />
        <Input
          label='Confirm Password (required)'
          name="passwordConformed"
          value={userStatePassword.passwordConfirm}
          handle={handlePswrd('passwordConfirm')}
          type="password"
          className='col-span-6'
          error={userErrStatePassword?.passwordConfirm}
          disabled={isPending}
        />
        <div className="flex justify-end mt-6 col-span-12">
          <Button
            onClick={handleEditUserPass}
            size='small'
            isNotFullWidth
            disabled={isPending}
          >
            Edit password
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UserEditForm;
