import { FC, PropsWithChildren } from "react";
import { TPageUrl } from "../routing/accessMap";
import CenterLayout from "./center";
import DashboardLayout from "./dashboard";
import Meta from "./Meta";
import React from "react";

export interface INavConfigItem {
  title: string,
  path: TPageUrl,
  dynamicPartPath?: string,
  icon?: React.ReactNode,
}
export interface INavConfig {
  subheader?: string,
  items: INavConfigItem[],
};

// -- Templates list
const layouts = {
  'dashboard': DashboardLayout,
  'center': CenterLayout,
}
export type TLayouts = keyof typeof layouts;

// --
// -- Wrap page into templates
// --
export const WrapInLayout: FC<PropsWithChildren<{
  title?: string,
  meta?: React.ReactNode,
  layout?: TLayouts,
}>> = ({
  title,
  meta,
  children,
  layout,
}) => {
  const Layout = layout && layouts?.[layout];
  return (
    <>
      <Meta title={title} meta={meta} />
      {Layout
        ? <Layout >{children}</Layout>
        : children
      }
    </>
  );
};
