import { ITicketType } from "../model/ITicketType";
import { ITicketStatus } from "../model/TTicketStatus";
import { getCommonData } from "../store/commonSlice";
import { useTypedSelector } from "./useTypedSelector";

export function useCommonData () {
  const {
    ticketTypes,
    ticketStatuses,
    ticketMessageTypes,
    ticketPriorities,
    vendors,
  } = useTypedSelector(getCommonData());

  const getTicketStatusById = (idSearch: number): ITicketStatus | undefined =>
    (ticketStatuses || []).find(({ id }) => id === idSearch);

  const getTicketTypeById = (idSearch: number): ITicketType | undefined =>
    (ticketTypes || []).find(({ id }) => id === idSearch);
  
  return {
    getTicketStatusById,
    getTicketTypeById,
  }
}