import { FC } from 'react';
import AddTicketForm from '../components/tickets/AddTicketForm';
import TicketDetails from '../components/tickets/TicketDetails';
import TicketTable from '../components/tickets/TicketsTable';
import { useTicket } from '../hooks/useTicket';
import { WrapInLayout } from '../layouts';
import Page3Line from '../layouts/dashboard/Page3Line';

const CSTicketsPage: FC = () => {
  const { activeTabId, isActiveTabNew } = useTicket();

  return (
    <WrapInLayout title="Service Tickets" layout="dashboard">
      <Page3Line>       
        {(!activeTabId && <TicketTable />)
        || activeTabId && isActiveTabNew && <AddTicketForm />
        || <TicketDetails />}      
      </Page3Line>
    </WrapInLayout> 
  );
};

export default CSTicketsPage;
