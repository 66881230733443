import { FC } from "react";
import InfoWidget from "../common/widgets/InfoWidget";
import TicketStatus from "./TicketStatus";
import parseISOToString from "../../utils/parseISOToString";
import TicketInternalNote from "./TicketInternalNote";
import { ITicket } from "../../model/ITicket";
import { icons } from "../../icons/IconSet";
import { useTicket } from "../../hooks/useTicket";

const TicketMainInfo: FC<{ ticket: ITicket }> = ({ ticket }) => {
  const { refreshTicket } = useTicket();
  return (
    <div className="flex flex-col gap-3">
      <div className="text-xl font-bold flex gap-2 items-center">
        <div>{ticket?.type?.name || ''}</div>
        <div>
          <div
            role="button"
            tabIndex={-1}
            onClick={() => refreshTicket(ticket.id)}
            className="text-gray-500 hover:text-primary"
          >
            {icons('refresh', { width: '18px' })}
          </div>
        </div>      
      </div>
      <div className="flex gap-6">
        <InfoWidget title="Status">
          <TicketStatus statusId={ticket?.status?.id || ''} isNotIcon />
        </InfoWidget>        
        <InfoWidget title="Ticket Created">
          {ticket?.created
            ? parseISOToString(ticket.created)
            : '-'
          }
        </InfoWidget>
        <InfoWidget title="Last Updated">
          {ticket?.updated
            ? parseISOToString(ticket.updated)
            : '-'
          }
        </InfoWidget>
        <InfoWidget title="Claim #">
          -
        </InfoWidget>
      </div>
      <TicketInternalNote ticket={ticket} />
    </div>
  );
};

export default TicketMainInfo;
