import { FC } from 'react';
import UserList from '../components/users/UserList';
import { WrapInLayout } from '../layouts';
import Page2Line from '../layouts/dashboard/Page2Line';
import TitlePage from '../components/common/title/TitlePage';

const UsersPage: FC = () => (
  <WrapInLayout title="Users" layout="dashboard">
      <Page2Line
        noPadding
        header={(
          <TitlePage>Users</TitlePage>
        )}
      >
        <UserList />
    </Page2Line>      
  </WrapInLayout>
);

export default UsersPage;
