import { FC } from "react";

const Marker: FC<{ status?: 'online' | 'offline' }> = ({ status = 'online' }) => (
  <div className="shrink-0">
    <div className={`
      rounded-[50%] w-[10px] h-[10px]
      ${{
        'online': 'bg-primary',
        'offline': 'bg-[#E11818]'
      }[status]}
    `} />
  </div>
);

export default Marker;
