import { FC, PropsWithChildren, ReactNode } from "react";

const Page2Line: FC<PropsWithChildren<{
  header?: ReactNode,
  children?: ReactNode,
  noPadding?: boolean,
}>> = ({
  header,
  children,
  noPadding = false,
}) => (
  <div className="bg-white rounded-md border-border_color border-[1px] h-full flex flex-col">
    {!!header && (
      <div className="min-h-[40px] bg-bg_light border-border_color border-b-[1px] p-4">
        {header}
      </div>
    )}
    <div className={`flex-1 overflow-x-scroll stylledScroll ${!noPadding ? 'p-4' : ''}`}>
      {children}
    </div>          
  </div>
);

export default Page2Line;
