import { FC, useEffect, useState } from "react";
import { useHandleField } from "../../hooks/useHandleField";
import { useTypedDispatch } from "../../hooks/useTypedDispatch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { icons } from "../../icons/IconSet";
import { getTiketsFilterSearch, IticketsFilterSearch, setTicketSearch, TICKETS_FILTER_SEARCH_TAGS } from "../../store/ticketsFilterSlice";
import Input from "../form/Input";

const initSeachState: IticketsFilterSearch = {
  type: 'orderId',
  search: '',
}

const TicketSearchField: FC = () => {
  const [searchState, setSearchState] = useState<IticketsFilterSearch>(initSeachState);
  const filterSearchState = useTypedSelector(getTiketsFilterSearch());
  const handle = useHandleField(setSearchState);
  const dispatch = useTypedDispatch();

  // -- Initiate local search state 
  useEffect(() => {
    setSearchState(() => filterSearchState || {
      type: 'supplierOrderId',
      search: '',
    });
  }, []);

  const onSearch = () => searchState.search && searchState.type && dispatch(setTicketSearch(searchState));
  
  const onReset = () => {
    dispatch(setTicketSearch(initSeachState));
    setSearchState(() => initSeachState);
  }

  return (
    <div className="flex gap-3 h-[31px] items-center bg-white pr-2 border-[1px] border-border_color rounded-lg">
      <Input
        name="ticket-search-type"
        size="tiny"
        options={Object.entries(TICKETS_FILTER_SEARCH_TAGS).map(([value, title]) => ({
          title: <span className="whitespace-nowrap">{title}</span>,
          value
        }))}
        value={searchState.type}
        className="p-0 leading-none leading-0 sm:leading-0"
        classNameField="py-0 leading-0 sm:leading-0 md:leading-0 outline-none focus:outline-none ring-transparent ring-0 focus:ring-0 border-white min-w-[120px]"
        handle={handle('type')}
      />

      <Input  
        name="ticket-search-str"
        size="tiny"
        value={searchState.search}
        classNameField="py-0 leading-0 sm:leading-0 md:leading-0 outline-none focus:outline-none ring-transparent ring-0 focus:ring-0 border-white"
        handle={handle('search')}
      />

      <div
        tabIndex={-1}
        role="button"
        className={`${searchState ? '' : 'opacity-50'}`}
        onClick={onSearch}
      >
        {icons('search', { width: '16px' })}
      </div>

      <div
        tabIndex={-1}
        role="button"
        className={`${searchState ? '' : 'opacity-50'}`}
        onClick={onReset}
      >
        {icons('x', { width: '16px' })}
      </div>
    </div>
  );
};

export default TicketSearchField;
