import { FC } from "react";

interface ITicketSettings {
  title: string,
  color: string,
}

const ticketStatuses: Record<string, ITicketSettings> = {
  1: { title: 'Open', color: '#23BB29' },
  2: { title: 'New', color: '#337BB6' },
  3: { title: 'Pending', color: '#4891FF' },
  4: { title: 'Closed', color: '#5F7484' },
  5: { title: 'Archived', color: '#2C3034' },
};

const TicketStatus: FC<{
  statusId?: string | number,
  name?: string,
  isNotIcon?: boolean,
}> = ({
  statusId = 1,
  name,
  isNotIcon = false,
}) => isNotIcon
  ? (
    <div style={{ color: ticketStatuses?.[statusId]?.color || 'grey' }}>
      {name || ticketStatuses?.[statusId]?.title || ' - '}
    </div>
  ) : (
    <div
      style={{ backgroundColor: ticketStatuses?.[statusId]?.color || 'grey' }}
      className="rounded-[5px] text-white font-bold py-[1px] px-[9px] tracking-wide whitespace-nowrap text-center flex w-min"
    >
      {name || ticketStatuses?.[statusId]?.title || ' - '}
    </div>
);

export default TicketStatus;
