import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ITicketMessageType } from '../model/ITicketMessageType';
import { ITicketType } from '../model/ITicketType';
import { IVendor, IVendorRole } from '../model/IVendor';
import { ITicketPriority } from '../model/TTicketPriority';
import { ITicketStatus } from '../model/TTicketStatus';
import { IAgentRole } from '../model/IAgent';

export interface ICommonInitState {
  ticketTypes?: ITicketType[],
  ticketStatuses?: ITicketStatus[],
  ticketMessageTypes?: ITicketMessageType[],
  ticketPriorities?: ITicketPriority[],
  vendors?: IVendor[],
  vendorRoles?: IVendorRole[],
  agentRoles?: IAgentRole[],
}

const authSlice = createSlice({
  name: 'commonSlice',
  initialState: {
    ticketTypes: undefined,
    ticketStatuses: undefined,
    ticketMessageTypes: undefined,
    ticketPriorities: undefined,
    vendors: undefined,
    vendorRoles: undefined,
    agentRoles: undefined,
  } as ICommonInitState,

  reducers: {
    setCommon: (state: ICommonInitState, action: PayloadAction<ICommonInitState>) => {
      state.ticketTypes = action.payload.ticketTypes;
      state.ticketStatuses = action.payload.ticketStatuses;
      state.ticketMessageTypes = action.payload.ticketMessageTypes;
      state.ticketPriorities = action.payload.ticketPriorities;
      state.vendors = action.payload.vendors;
      state.vendorRoles = action.payload.vendorRoles;
      state.agentRoles = action.payload.agentRoles;
    },
  },
});

// Reducers
export default authSlice.reducer;
export const {
  setCommon,
} = authSlice.actions;

// Selectors
export const getCommonData = () => (state: RootState) => state.commonSlice;
export const getTicketTypes = () => (state: RootState) => state.commonSlice.ticketTypes;
export const getTicketStatuses = () => (state: RootState) => state.commonSlice.ticketStatuses;
export const getTicketMessageTypes = () => (state: RootState) => state.commonSlice.ticketMessageTypes;
export const getTicketPriorities = () => (state: RootState) => state.commonSlice.ticketPriorities;
export const getVendors = () => (state: RootState) => state.commonSlice.vendors;
export const getVendorRoles = () => (state: RootState) => state.commonSlice.vendorRoles;
export const getAgentRoles = () => (state: RootState) => state.commonSlice.agentRoles;
