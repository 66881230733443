import { FC } from "react";
import { IInput } from "./Input";
import Label from "./Label";

const Checkbox: FC<Omit<IInput, 'value' | 'handle'> & {
  value: boolean,
  handleToggle?: () => unknown,
  enablePropagation?: boolean,
}> = ({
  value,
  handleToggle,
  label,
  size = 'medium',
  className,
  enablePropagation = false,
}) => (
  <div className={`
    flex flex-col
    ${className || ''}        
  `}>    
    <button
      type="button"
      role="switch"
      className="flex items-center gap-2"
      {...(handleToggle
        ? { onClick: handleToggle }
        : {}
      )}
      onClick={(e) => {
        !enablePropagation && e.stopPropagation();          
        handleToggle && handleToggle();
      }}
    >
      {!!value
        ? (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_951_1356)">
            <rect width="16" height="16" rx="4.26667" fill="#337AB7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2313 3.97534C12.5395 4.17683 12.626 4.58999 12.4245 4.89815L7.89114 11.8315C7.7843 11.9949 7.61122 12.1032 7.41757 12.128C7.22392 12.1527 7.02916 12.0913 6.88471 11.9599L3.95138 9.29328C3.67894 9.04561 3.65886 8.62398 3.90653 8.35154C4.1542 8.0791 4.57584 8.05902 4.84827 8.30669L7.20306 10.4474L11.3085 4.16849C11.51 3.86032 11.9232 3.77385 12.2313 3.97534Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_951_1356">
            <rect width="16" height="16" rx="4.26667" fill="white"/>
            </clipPath>
            </defs>
          </svg>
        ) : (
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#18181B"/>
          </svg>  
        ) 
      }
      {!!label && <Label size={size}>{label}</Label>}
    </button>
  </div>
);

export default Checkbox;
