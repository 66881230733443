import { FC, PropsWithChildren } from "react";
import Divider from "../common/Divider";

const FormTitle: FC<PropsWithChildren> = ({ children }) => (
  <div className="col-span-12 text-lg my-4">
    {children}
  </div>
);

export default FormTitle;
