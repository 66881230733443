import { useLocation, useSearchParams } from "react-router-dom";
import { IUrlParamsMap } from "../routing/IUrlParamsMap";

type TUrlSchemaKeys = Partial<keyof IUrlParamsMap>;

// --
// -- Example:
/*  const param = useSchemaSearchParams();
    param
      .delete('auth-form')
      .delete('email-remember')
      .push();
*/
export default function useSchemaSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const paramMap = new URLSearchParams(location.search);  
  // const paramMap = new URLSearchParams(searchParams);  

  return {
    get: <K extends TUrlSchemaKeys>(key: K): IUrlParamsMap[K] | '' => paramMap.get(key) as IUrlParamsMap[K] || '',     
    set: function <K extends TUrlSchemaKeys>(key: K, value: IUrlParamsMap[K]) {
      paramMap.set(key, value as string);
      return this;
    },
    delete: function <K extends TUrlSchemaKeys>(key: K) {
      paramMap.delete(key);
      return this;
    },
    push: function () {
      setSearchParams(paramMap, { replace: true });
      return this;
    },
    toString: () => paramMap.toString(),
    searchParams,
    paramMap,
    params: () => Object.fromEntries(paramMap.entries()),
    // Pagable
    // getPageParams: function (): IPageParams {
    //   return {
    //     page: Number(paramMap.get('page')) || 1,
    //     size: Number(paramMap.get('size')) || 1,
    //     q: paramMap.get('q') || '',
    //     sort: paramMap.get('sort') || '',
    //     sortDirection: paramMap.get('sortDirection') === 'asc' && 'asc'
    //       || paramMap.get('sortDirection') === 'desc' && 'desc'
    //       || undefined,
    //   };
    // },
    // updatePageParams: function (pageParams: Partial<IPageParams>) {
    //   Object.entries(pageParams).forEach(([key, value]) => paramMap.set(key, value as string));
    //   replace(`${pathname}?${String(paramMap)}`);
    // },
  }
}