
import { TRole } from "../model/IRole";

// --
export type TPageUrl =
| '/oauth/signIn'

| '/dashboard'

| '/customer-service/cs-tickets'

| '/sales/sales-reps'
| '/sales/add-edit-product'
| '/sales/coupon-discount-system'
| '/sales/special-offers'
| '/sales/make-an-offer-discount-rules'

| '/orders/recent-orders'
| '/orders/orders-by-create-dates'
| '/orders/orders-by-vehicle'
| '/orders/orders-by-status'
| '/orders/orders-by-website'
| '/orders/orders-by-sales-reps'
| '/orders/freight-discount-by-date'
| '/orders/calling-cart-abandoners'
| '/orders/order-lookup'
| '/orders/checkout-tracking'
| '/orders/quick-status'
| '/orders/vin-oem-tracking'
| '/orders/in-stock-verification-stats'

| '/users'
| '/users/new'

;

type IAccess<T extends string | number | symbol> = {
  [key in T]: (Partial<TRole | '*'>)[];
};

// --
// -- Access map
// --
export const pagesAccess: IAccess<TPageUrl> = {
  '/oauth/signIn':                        ['*'],

  '/dashboard':                           ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_AGENT', 'ROLE_VENDOR'],

  '/customer-service/cs-tickets' :        ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_VENDOR'],

  '/sales/sales-reps' :                   ['ROLE_ADMIN'],
  '/sales/add-edit-product' :             ['ROLE_ADMIN'],
  '/sales/coupon-discount-system' :       ['ROLE_ADMIN'],
  '/sales/special-offers' :               ['ROLE_ADMIN'],
  '/sales/make-an-offer-discount-rules' : ['ROLE_ADMIN'],
  
  '/orders/recent-orders' :               ['ROLE_ADMIN'],
  '/orders/orders-by-create-dates' :      ['ROLE_ADMIN'],
  '/orders/orders-by-vehicle' :           ['ROLE_ADMIN'],
  '/orders/orders-by-status' :            ['ROLE_ADMIN'],
  '/orders/orders-by-website' :           ['ROLE_ADMIN'],
  '/orders/orders-by-sales-reps' :        ['ROLE_ADMIN'],
  '/orders/freight-discount-by-date' :    ['ROLE_ADMIN'],
  '/orders/calling-cart-abandoners' :     ['ROLE_ADMIN'],
  '/orders/order-lookup' :                ['ROLE_ADMIN'],
  '/orders/checkout-tracking' :           ['ROLE_ADMIN'],
  '/orders/quick-status' :                ['ROLE_ADMIN'],
  '/orders/vin-oem-tracking' :            ['ROLE_ADMIN'],
  '/orders/in-stock-verification-stats' : ['ROLE_ADMIN'],

  '/users'  :       ['ROLE_ADMIN'],
  '/users/new' :    ['ROLE_ADMIN'],
};

export const settingAccess = {
  'add-user':     ['ROLE_ADMIN'],
  'edit-user':    ['ROLE_ADMIN'],
  'delete-user':  ['ROLE_ADMIN'],

  'ticket-asigned-agent':   ['ROLE_ADMIN', 'ROLE_AGENT'],
  'ticket-asigned-vendor':  ['ROLE_ADMIN', 'ROLE_VENDOR'],
  'ticket-asigned-all':     ['ROLE_ADMIN'],
  
  'ticket-public-msg':      ['ROLE_ADMIN', 'ROLE_AGENT'],
  'ticket-internal-msg':    ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_VENDOR'],
  'ticket-internal-scope-all':    ['ROLE_ADMIN'],
  'ticket-internal-scope-vendor': ['ROLE_AGENT'],
  'ticket-internal-scope-agent':  ['ROLE_VENDOR'],

  'order-priceFields':      ['ROLE_ADMIN', 'ROLE_AGENT'],
  'order-details':          ['ROLE_ADMIN', 'ROLE_AGENT'],
};

export type TSettingNames = keyof typeof settingAccess;
