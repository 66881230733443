import { FC, useEffect, useState } from "react";
import { orderApi } from "../../api/order.api";
import { useHandleField } from "../../hooks/useHandleField";
import { useTicket } from "../../hooks/useTicket";
import Page3Cols from "../../layouts/dashboard/Tpl3Cols";
import { IOrder, IOrderItem } from "../../model/IOrder";
import { ITicketType } from "../../model/ITicketType";
import Divider from "../common/Divider";
import TicketActionLog from "./TicketActionLog";
import TicketMainInfo from "./TicketMainInfo";
import TicketMsg from "./TicketMsg";
import TicketOrderDetails from "./TicketOrderDetails";
import TicketParamsForm, { ITicketParamsState } from "./TicketParamsForm";
import toast from "react-hot-toast";
import { msg } from "../../msg";
import Input from "../form/Input";
import TicketMessages from "./TicketMessages";
import UserSelect from "../user/UserSelect";
import { IMessageType } from "../../model/IMessage";
import { useTicketMessage } from "../../hooks/useTicketMessage";
import { useAccess } from "../../hooks/useAccess";
import TicketActionDropDown from "../ticketAction/[DEPRICATED]TicketActionDropDown";
import TicketAction from "../ticketAction/TicketAction";

export type TTicketState =  ITicketParamsState & {
  orderId?: IOrder['orderId'],
  orderItemId?: IOrderItem['id'],
  typeId: ITicketType['id'],
};
export interface ITicketNewMsg {
  type?: IMessageType,
  toUserId?: string,
  message: string,
  attachments: File[],
}

const TicketDetails: FC = () => {
  // --
  // -- Msg options for current user role
  // --
  const { getSettingAccess } = useAccess();
  const msgOption: { title: string, value: IMessageType }[] = [
    ...(getSettingAccess('ticket-public-msg') ? [{ title: 'Public Message',  value: 'Public' as IMessageType}] : []),
    ...(getSettingAccess('ticket-internal-msg') ? [{ title: 'Internal Message',  value: 'Private' as IMessageType}] : []),
  ]

  // --
  // -- Init states
  // --
  const initTicketDetails: TTicketState = {
    assignedUserId: '',
    assignedVendorId: '',
    followers: '',
    extraFollowers: '',
    priorityId: 1,
    statusId: 1,
    typeId: 1,
  };
 
  const initTicketNewMsg: ITicketNewMsg = {
    type: msgOption?.[0]?.value,
    toUserId: '',
    message: '',
    attachments: [],
  };

  const { currentTicketFromStore, fetchFollowers, editTicketParams } = useTicket();
  const { addMessage } = useTicketMessage();
  const [stateTicketErr, setStateTicketErr] = useState<Partial<Record<keyof TTicketState, string>>>();
  const [stateTicket, setStateTicket] = useState<TTicketState>(initTicketDetails);
  const [stateTicketOriginal, setStateTicketOriginal] = useState<TTicketState>(initTicketDetails);
  const [loading, setLoading] = useState<boolean>(false);
  const [msgState, setMsgState] = useState<ITicketNewMsg>(initTicketNewMsg);
  const [msgStateErr, setMsgStateErr] = useState<Partial<Record<keyof ITicketNewMsg, string>>>();
  

  // --
  // -- Set default recipient for the internal message
  // --
  useEffect(() => {
    currentTicketFromStore?.id && setMsgState((prevState) => ({
      ...prevState,
      toUserId: (getSettingAccess('ticket-internal-scope-all') && '')
        || (getSettingAccess('ticket-internal-scope-vendor') && (currentTicketFromStore?.assignedVendorUser?.id || ''))
        || (getSettingAccess('ticket-internal-scope-agent') && (currentTicketFromStore?.assignedUser?.id || ''))
        || ''
    }));    

  }, [currentTicketFromStore?.id]);

  const { data: orders } = orderApi.useSearchOrderQuery(currentTicketFromStore?.orderId || '', {
    skip: !currentTicketFromStore?.orderId
  });
  
  const handleMsgField = useHandleField(setMsgState);
  const handleAddMsg = async () => {
    if (!currentTicketFromStore?.id) return;

    setLoading(() => true);
    setMsgStateErr(() => undefined);

    const errs = await addMessage(currentTicketFromStore.id, msgState);
    if (errs) 
      setMsgStateErr(() => errs);
    else {
      toast.success(msg('ticket-message-added'));
      setMsgState(() => initTicketNewMsg);
    }
    setLoading(() => false);
  };

  // --
  // -- Update ticket params flow
  // --
  const handleUpdateTicket = async () => {
    if (!currentTicketFromStore?.id) return;
    setLoading(() => true);
    setStateTicketErr(() => undefined);

    const errs = await editTicketParams({
      idTicket: currentTicketFromStore.id,
      ...stateTicket,
    });

    if (errs) setStateTicketErr(() => errs);
    else toast.success(msg('success-ticket-editing'));

    setLoading(() => false);
  };
  
  // --
  // -- Fill ticket form
  // --
  useEffect(() => {
    if (currentTicketFromStore) {
      const [followers, extraFollowers] = fetchFollowers(currentTicketFromStore.followers);     

      const currentTicketState = {
        assignedUserId: currentTicketFromStore?.assignedUser?.id || '',
        assignedVendorId: currentTicketFromStore?.assignedVendorUser?.id || '',
        followers,
        extraFollowers,
        priorityId: currentTicketFromStore?.priority?.id || 1,
        statusId: currentTicketFromStore?.status?.id || 1,
        typeId: currentTicketFromStore?.type?.id || 1,
        orderId: currentTicketFromStore.orderId,
        orderItemId: currentTicketFromStore?.orderItemId || undefined,
      }

      setStateTicket(() => currentTicketState);
      setStateTicketOriginal(() => currentTicketState);
    }
  }, [currentTicketFromStore]);
  
  return !currentTicketFromStore
    ? null
    : (
      <Page3Cols
        leftSidebar={<TicketParamsForm
          state={stateTicket}
          stateOriginal={stateTicketOriginal}
          setState={setStateTicket} 
          stateErr={stateTicketErr}
          loading={loading}
          handleUpdateTicket={handleUpdateTicket}
        />}
        rightSidebar={
          <>
            <div className="p-4">
              <TicketOrderDetails
                orderItemId={Number(stateTicket.orderItemId)}
                {...(orders?.[0] || {})}
              />
            </div>
            <Divider />
            <div className="p-4">
              <TicketActionLog
                title="Action Log"
                logItems={currentTicketFromStore.actionLog}
              />
            </div>
          </>
        }
      >
        <div className="flex-1 h-max">
          <div className="p-4">
            <TicketMainInfo ticket={currentTicketFromStore} />              
          </div>
          <Divider />
          <TicketMessages ticket={currentTicketFromStore} />
          <TicketAction
            ticket={currentTicketFromStore}
            loading={loading}
            setLoading={setLoading}
          />
        </div>

        <div className="">
          <Divider />
          <div className="p-4 flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <div className="flex gap-4">
                <div className="w-[150px]">
                  <Input
                    name="msgType"
                    value={msgState.type || ''}
                    handle={handleMsgField('type')}
                    disabled={loading}
                    error={msgStateErr?.type}
                    options={msgOption}
                  />
                </div>
                {msgState.type === 'Private' && (
                  getSettingAccess('ticket-internal-scope-all')
                  || getSettingAccess('ticket-internal-scope-vendor')
                  || getSettingAccess('ticket-internal-scope-agent')
                ) && (
                  <div className="flex gap-4">
                    <div className="mt-2">To</div>
                    <div className="w-[400px]">
                      <UserSelect
                        name="toUserId"
                        type="text"
                        value={msgState.toUserId || ''}
                        handle={handleMsgField('toUserId')}
                        disabled={loading}
                        error={msgStateErr?.toUserId}
                        isError={!!msgStateErr?.toUserId}
                        scope={
                          (getSettingAccess('ticket-internal-scope-vendor') && 'tickets_vendor')
                          || (getSettingAccess('ticket-internal-scope-agent') && 'tickets_agent')
                          || 'tickets'
                        }
                      />
                    </div>
                  </div>
                )}                
              </div>
              <TicketAction ticket={currentTicketFromStore} mode="dropDown" />
            </div>
            <TicketMsg
              name="message"
              value={msgState.message || ''}
              handle={handleMsgField('message')}
              error={msgStateErr?.message}
              buttonTitle="Submit"
              handleClick={handleAddMsg}            
              disabled={loading}
              filesState={msgState.attachments}
              setFileState={(files: File[]) => setMsgState((prevState) => ({
                ...prevState,
                attachments: files,
              }))}
            />
          </div>
        </div>
      </Page3Cols>
    );
}

export default TicketDetails;
