import { FC, PropsWithChildren } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { isCurrentPath } from "../../utils/isCurrentPath";

const SideBarNavLink: FC<PropsWithChildren<{
  to: string,
  onClick?: () => unknown,
  isCurrent?: boolean,
}>> = ({
  children,
  to,
  onClick,
  isCurrent = false,
}) => (
  <NavLink
    to={to}
    {...(onClick ? { onClick } : {})}
    className={`
      block py-[6px] px-4
      ${isCurrent
        ? 'bg-bg_dark'
        : 'hover:text-primary hover:bg-slate-50'
      }
    `}                    
  >
    {children}
  </NavLink>
);


export default SideBarNavLink;
