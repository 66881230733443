import { FC } from "react";
import { icons } from "../../icons/IconSet";
import { useTicket } from "../../hooks/useTicket";

const NotificationWidget: FC = () => {
  const { refreshAllTickets } = useTicket();

  return (
    <div className="w-[180px] flex items-center justify-center gap-2">
      {icons('concierge-bell')}
      {icons('search')}
      <div
        role="button"
        tabIndex={-1}
        onClick={() => refreshAllTickets()}
        className=""
      >
        {icons('refresh')}
      </div>
      <div className="flex items-center justify-center gap-2 bg-bg_main rounded-md px-2 py-1 leading-3">
        {icons('ticket')}
        22
      </div>
    </div>
);
}

export default NotificationWidget;
