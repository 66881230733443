import { FC } from "react";
import TicketStatus from "./TicketStatus";
import { icons } from "../../icons/IconSet";

const TicketTab: FC<{
  id?: string | number,
  userName?: string,
  statusId?: number,
}> = ({
  id,
  userName,
  statusId,
}) => (
  <>
    <div className="">{icons('user-round')}</div>
    <div className="">{userName || ''}</div>
    <div className=""><TicketStatus statusId={statusId} /></div>
    <div className="">Ticket #{id || ''}</div>
  </>
);

export default TicketTab;
