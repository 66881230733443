import { FC, useEffect, useState } from "react";
import { useHandleField } from "../../hooks/useHandleField";
import Divider from "../common/Divider";
import Input from "../form/Input";
import UserMultiSearchField from "../user/UserMultiSearchField";
import UserSelect from "../user/UserSelect";
import TicketPrioritySelect from "./TicketPrioritySelect";
import TicketStatusSelect from "./TicketStatusSelect";
import { ITicketPriority } from "../../model/TTicketPriority";
import { ITicketStatus } from "../../model/TTicketStatus";
import Button from "../form/Button";
import { isEqualPlaneObjs } from "../../utils/isEqualObjs";
import { useAccess } from "../../hooks/useAccess";

export interface ITicketParamsState {
  assignedUserId: string,
  assignedVendorId: string,
  followers: string,
  extraFollowers: string,
  priorityId: ITicketPriority['id'],
  statusId: ITicketStatus['id']
};

const TicketParamsForm: FC<{
  state: ITicketParamsState,
  stateOriginal?: ITicketParamsState,
  stateErr?: Partial<Record<keyof ITicketParamsState, string>>,
  setState: React.Dispatch<React.SetStateAction<ITicketParamsState & any>>,
  loading?: boolean,
  handleUpdateTicket?: () => unknown,
}> = ({
  state,
  stateOriginal,
  stateErr,
  setState,
  loading,
  handleUpdateTicket,
}) => {
  const handleField = useHandleField(setState);
  const [isNoOriginal, setIsNoOriginal] = useState<boolean>(false);
  const { getSettingAccess } = useAccess();

  // --
  // -- Check if original state has changed
  // --
  useEffect(() => {
    setIsNoOriginal(() => !!stateOriginal && !isEqualPlaneObjs(state, stateOriginal)); 
  }, [state]);

  const handleReset = () => stateOriginal && setState(() => stateOriginal);

  return (
    <div className="flex flex-col gap-4">      
      {/* <VendorSelect
        label="Assigned" 
        name="assigned"
        type="text"
        value={state.assigned}
        handle={handleField('assigned')}
        disabled={loading}
        error={stateErr?.assigned}
      /> */}
      {!!getSettingAccess('ticket-asigned-agent') && (
        <UserSelect
          label="Assigned Agent" 
          name="assignedUserId"
          type="text"
          value={state.assignedUserId}
          handle={handleField('assignedUserId')}
          disabled={loading}
          error={stateErr?.assignedUserId}
          scope="tickets_agent"
        />
      )}
      {!!getSettingAccess('ticket-asigned-vendor') && (
        <UserSelect
          label="Assigned Vendor" 
          name="assignedVendorId"
          type="text"
          value={state.assignedVendorId}
          handle={handleField('assignedVendorId')}
          disabled={loading}
          error={stateErr?.assignedVendorId}
          scope="tickets_vendor"
        />
      )}
      
      <UserMultiSearchField
        label="Followers" 
        name="followers"
        value={state.followers}
        handle={handleField('followers')}
        disabled={loading}
        error={stateErr?.followers}
        width="100px"
      />
      <Input
        label="External Followers" 
        name="extraFollowers"
        type="text"
        size="small"
        value={state.extraFollowers}
        handle={handleField('extraFollowers')}
        disabled={loading}
        error={stateErr?.extraFollowers}
      />
      <Divider />
      <TicketPrioritySelect
        label="Priority" 
        name="priorityId"
        value={state.priorityId}
        handle={handleField('priorityId')}
        disabled={loading}
        error={stateErr?.priorityId}
      />
      <TicketStatusSelect
        label="Status" 
        name="statusId"
        value={state.statusId}
        handle={handleField('statusId')}
        disabled={loading}
        error={stateErr?.statusId}
      />
      {!!isNoOriginal && (
        <div className="flex mt-4 gap-2">
          <Button
            variant="outline"
            size="small"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            size="small"
            onClick={() => handleUpdateTicket && handleUpdateTicket()}
          >
            Update
          </Button>
        </div>
      )}
      
    </div>
  );
}

export default TicketParamsForm;
