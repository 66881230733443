/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Loading from './components/common/loading/Loading';
import { useTypedSelector } from './hooks/useTypedSelector';
import { useAuth } from './hooks/useAuth';
import { getCurrentUser } from './store/authSlice';
import { useAccess } from './hooks/useAccess';
import { siteMap } from './routing/siteMap';
import { commonApi } from './api/common.api';
import { useTypedDispatch } from './hooks/useTypedDispatch';
import { setCommon } from './store/commonSlice';
import toast from 'react-hot-toast';

function App() {
  const { setMyself } = useAuth();
  const dispatch = useTypedDispatch();
  const user = useTypedSelector(getCurrentUser());
  const { getPageAccess } = useAccess();
  const [getCommon] = commonApi.useLazyGetCommonQuery();
  
  // --
  // -- Get myself
  // --
  useEffect(() => { setMyself(); }, []);

  // --
  // -- Get common info
  // --
  useEffect(() => {
    user?.id && getCommon({})
      .then(({ data }) => dispatch(setCommon(data || {})))
      .catch((err) => toast.error('Faild to load service common parameters!'))
  }, [user?.id])

  return user === undefined
    ? <Loading isfullScreen />
    : <RouterProvider
        router={createBrowserRouter(siteMap(window.location.href.replace(window.location.origin, ''), getPageAccess))}
        fallbackElement={<> </>}
    />
}

export default App;
