import { FC, useState } from "react";
import { useHandleField } from "../../hooks/useHandleField";
import { useTicket } from "../../hooks/useTicket";
import { IOrder, IOrderItem } from "../../model/IOrder";
import { ITicketType } from "../../model/ITicketType";
import Divider from "../common/Divider";
import OrderItemSelect from "../order/OrderItemSelect";
import OrderSelectSearch from "../order/OrderSelectSearch";
import TicketMsg from "./TicketMsg";
import TicketOrderDetails from "./TicketOrderDetails";
import TicketParamsForm, { ITicketParamsState } from "./TicketParamsForm";
import TicketTypeSelect from "./TicketTypeSelect";
import toast from "react-hot-toast";
import { msg } from "../../msg";

export type TNewTicketState =  ITicketParamsState & {
  orderId?: IOrder['orderId'],
  orderItemId?: IOrderItem['id'],
  typeId: ITicketType['id'],
  message?: string,
  attachments: File[],
};

const AddTicketForm: FC = () => {
  const [stateTicketErr, setStateTicketErr] = useState<Partial<Record<keyof TNewTicketState, string>>>();
  const [stateTicket, setStateTicket] = useState<TNewTicketState>(() => ({
    assignedUserId: '',
    assignedVendorId: '',
    followers: '',
    extraFollowers: '',
    priorityId: 1,
    statusId: 1,
    typeId: 1,
    attachments: [],
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<IOrder>();
  const handle = useHandleField(setStateTicket);   
  
  const { addTicket } = useTicket();

  const handleAddTicket = () => {
    setLoading(() => true);
    setStateTicketErr(() => undefined);

    addTicket(stateTicket)
      .then((errs) => {
        if (errs) return setStateTicketErr(() => errs);
        toast.success(msg('success-ticket-adding'));        
      })
      .catch((err) => setStateTicketErr(() => err))
      .finally(() => setLoading(() => false));
  }; 
  
  return (
    <div className="w-full flex h-full">
      <div className="basis-[265px] p-4">
        <TicketParamsForm
          state={stateTicket}
          setState={setStateTicket}
          stateErr={stateTicketErr}
          loading={loading}
        />
      </div>
      <div className="flex-1 flex flex-col border-border_color border-l-[1px] border-r-[1px]">
        <div className="flex-1  p-4">
          <TicketTypeSelect
            label="Ticket Type"
            name="typeId"
            value={stateTicket.typeId || ''}
            handle={handle('typeId')}
            error={stateTicketErr?.typeId}
            disabled={loading}
          />
        </div>
        <Divider />
        <div className="p-4">
          <TicketMsg
            name="message"
            value={stateTicket.message || ''}
            handle={handle('message')}
            error={stateTicketErr?.message}
            buttonTitle="Add Ticket"
            handleClick={handleAddTicket}            
            disabled={loading}
            filesState={stateTicket.attachments}
              setFileState={(files: File[]) => setStateTicket((prevState) => ({
                ...prevState,
                attachments: files,
            }))}
          />
        </div>
      </div>
      <div className="basis-[265px] p-4">
        <div className="flex flex-col gap-4 -mt-1">
          <OrderSelectSearch
            label="Order"
            name="order"
            value={stateTicket.orderId || ''}
            handle={handle('orderId')}
            error={stateTicketErr?.orderId}
            selectHandler={(checkedOrder) => {
              setOrder(() => checkedOrder);
              setStateTicketErr((prevState) => ({
                ...prevState,
                orderId: undefined,
              }));
            }}
            disabled={loading}
          />
          {!!order?.orderItems?.length && (
            <OrderItemSelect
              label="Order Item"
              size="small"
              name="orderItemId"
              value={stateTicket.orderItemId || ''}
              handle={handle('orderItemId')}
              error={stateTicketErr?.orderItemId}
              orderItems={order.orderItems}              
              disabled={loading}
            />
          )}
          {!!order?.orderId && <Divider />}
        </div>
        <TicketOrderDetails
          orderItemId={Number(stateTicket.orderItemId)}
          {...(order || {})}
        />
      </div>
    </div>
  );
}

export default AddTicketForm;
