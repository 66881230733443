import { FC } from "react";
import { ITicket } from "../../model/ITicket";
import TicketMessage from "./TicketMessage";

const TicketMessages : FC<{ ticket: ITicket }> = ({ ticket }) => (
  <div className="flex flex-col gap-10 p-4 overflow-y-scroll stylledScroll">
    {ticket?.messages?.map((message) => <TicketMessage key={`msg-${message.id}`} message={message} ticket={ticket} />)}
  </div>
);

export default TicketMessages;
